import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import Input from "../../components/form/inputs/Input";
import TillLogin from "../../components/login/staff-number-login";
import LoaderButton from "../../components/LoaderButton";
import RealTimeAgent from "../../components/mqtt/MqttAgent";

import { getFulfillerData, getCCShipments, updateShipment } from "../../actions/ShippingActionsNew";
import { errorNotification, removeNotification } from "../../actions/NotificationActions";

import METHOD from "../../constants/shipping/MethodTypesNew";

const mapStateToProps = state => ({
    user: state.authState,
    loading: state.shippingState.isLoading,
    fulfiller: state.shippingState.fulfiller,
    shipments: state.shippingState.shipments,
    auspost: state.shippingState.auspost,
    loggedIn: state.shippingState.loggedIn,
    errors: state.notificationState.errors
});

const mapDispatchToProps = dispatch => ({
    getFulfillerData: (futuraId) => dispatch(getFulfillerData(futuraId)),
    getCCShipments: () => dispatch(getCCShipments()),
    updateShipment: (shipmentId, status, reason, collection) => 
        dispatch(updateShipment(shipmentId, status, reason, collection)),
    errorNotification: (message) => dispatch(errorNotification(message)),
    removeNotification: (message) => dispatch(removeNotification(message))
});

class Collect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fulfiller: '',
            loggedIn: false,
            disabledManifest: false,
            labels: 0,
            barcodeOne: '',
            barcodes: [],
            bags: 1,
            inputs: [],
            selectedShipment: null,
            notFound: false
        };
    }

    componentDidMount() {
        this.props.getCCShipments();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.notFound) {
            if (!this.props.errors.includes('Shipment Not Found')) {
                this.props.errorNotification('Shipment Not Found');
            }
        } else if (prevState.notFound && !this.state.notFound) {
            this.props.removeNotification('Shipment Not Found');
        }
    }

    handleScan = (scan, type) => {
        const cleanScan = scan.trim();
        console.log(`[handleScan] type: ${type}, value: ${cleanScan}`);

        if (type === "barcode-one") {
            this.setState({ barcodeOne: cleanScan });

            if (!Array.isArray(this.props.shipments)) {
                console.log('[handleScan] No shipments array found');
                this.setState({ notFound: true });
                return;
            }

            const matchingShipments = this.props.shipments.filter(s => 
                s.shipmentId === cleanScan.replace(/-1$/, '') && 
                s.carrier === METHOD.CLICKCOLLECT
            );

            console.log(`[handleScan] Found ${matchingShipments.length} matching shipments`);

            if (matchingShipments.length === 1) {
                const matchingShipment = matchingShipments[0];
                const bagCount = matchingShipment.bagCount || 1;
                console.log(`[handleScan] Selected shipment with ID: ${matchingShipment.shipmentId}, bag count: ${bagCount}`);
                const newBarcodes = new Array(bagCount).fill('');
                this.setState({
                    bags: bagCount,
                    barcodes: newBarcodes,
                    selectedShipment: matchingShipment,
                    notFound: false
                });
            } else {
                console.log('[handleScan] No matching shipment found');
                this.setState({ 
                    notFound: true,
                    selectedShipment: null 
                });
            }
        }
    };

    handleBarcodeChange = (index, value) => {
        console.log(`[handleBarcodeChange] index: ${index}, value: ${value}`);
        const newBarcodes = [...this.state.barcodes];
        newBarcodes[index] = value;

        console.log(`[handleBarcodeChange] Updated barcodes array:`, newBarcodes);
        this.setState({ barcodes: newBarcodes }, () => {
            console.log('[handleBarcodeChange] State updated, calling checkAllBarcodesValid');
            this.checkAllBarcodesValid();
        });
    };

    checkAllBarcodesValid = () => {
        const { barcodes, bags, selectedShipment } = this.state;

        if (!selectedShipment) {
            console.log('[checkAllBarcodesValid] No selected shipment, returning');
            return;
        }

        console.log(`[checkAllBarcodesValid] Checking barcodes:`, barcodes);
        console.log(`[checkAllBarcodesValid] Expected bag count: ${bags}`);

        const allValid = barcodes.length === bags && 
                         barcodes.every((barcode, index) => {
                             const expectedPrefix = selectedShipment.shipmentId;
                             const expectedBarcode = `${expectedPrefix}-${index + 2}`;
                             const isValid = barcode === expectedBarcode;
                             console.log(`[checkAllBarcodesValid] Barcode ${index}: ${barcode}, expected: ${expectedBarcode}, valid: ${isValid}`);
                             return isValid;
                         });

        console.log(`[checkAllBarcodesValid] All barcodes valid: ${allValid}`);
    };

    handleLogin = (fulfiller) => {
        this.props.getFulfillerData(fulfiller);
        this.setState({ loggedIn: true });
    };

    resetScan = (event) => {
        event.preventDefault();
        console.log('[resetScan] Resetting scan state');
        this.setState({
            barcodeOne: '',
            barcodes: [],
            inputs: [],
            selectedShipment: null
        });
    };

    validateBarcode = () => {
        const { barcodeOne, barcodes, bags, selectedShipment } = this.state;

        if (!selectedShipment || !barcodeOne) {
            console.log('[validateBarcode] No shipment or barcode one, disabling button');
            return true;
        }

        const validBarcodes = barcodes.filter((barcode, index) => {
            if (!barcode) return false;
            const expectedPrefix = selectedShipment.shipmentId;
            const expectedBarcode = `${expectedPrefix}-${index + 2}`;
            const isValid = barcode === expectedBarcode;
            console.log(`[validateBarcode] Barcode ${index}: ${barcode}, expected: ${expectedBarcode}, valid: ${isValid}`);
            return isValid;
        }).length;

        const isDisabled = validBarcodes !== bags;
        console.log(`[validateBarcode] Valid barcodes: ${validBarcodes}/${bags}, button disabled: ${isDisabled}`);
        return isDisabled;
    };

    fulfillShipment = (event) => {
        event.preventDefault();
        const shipmentId = this.state.selectedShipment.shipmentId;
        console.log(`[fulfillShipment] Fulfilling shipment with ID: ${shipmentId}`);
        this.props.updateShipment(shipmentId, "COLLECTED", false, true);
    };


    renderLoggedInView = () => {
        const { loading, fulfiller, auspost } = this.props;
        const { selectedShipment, barcodes, bags } = this.state;

        console.log('[renderLoggedInView] Rendering logged-in view');
        console.log('[renderLoggedInView] Selected shipment:', selectedShipment);
        console.log('[renderLoggedInView] Current barcodes:', barcodes);

        const bagInputs = [];
        if (selectedShipment) {
            const bagCount = selectedShipment.bagCount || 1;
            console.log(`[renderLoggedInView] Creating ${bagCount} bag inputs`);
            for (let i = 0; i < bagCount; i++) {
                bagInputs.push(
                    <Input
                        key={`barcode-${i}`}
                        id={`barcode-${i}`}
                        name={`barcode-${i}`}
                        controlId={`barcode-${i}`}
                        placeholder={`SCAN OR TYPE IN BARCODE FROM SHOP BAG ${i+1}`}
                        onChange={(e) => this.handleBarcodeChange(i, e.target.value)}
                        value={barcodes[i] || ''}
                        type="text"
                        autoComplete="off"
                    />
                );
            }
        }

        return (
            <div className="wrapper">
                <div className="heading">
                    <div className="left-heading">
                        <h1 className="heading-text">Your Click and Collect shipments</h1>
                        {loading ? (
                            <h2 className="small-heading-text">
                                Hey {fulfiller.fName}! hang tight while we load your click & collect shipments.
                            </h2>
                        ) : (
                            auspost && (
                                <h2 className="medium-heading-text">
                                    Scan the barcode on the customers confirmation email and shop bag and confirm customer has collected the order.
                                </h2>
                            )
                        )}
                    </div>
                </div>

                <Row style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                    <Col sm={4}>
                        <Input 
                            id="barcode-one"
                            name="barcode-one"
                            controlId="barcode-one"
                            placeholder="SCAN OR TYPE IN BARCODE FROM CUSTOMERS EMAIL/SMS"
                            onChange={(e) => this.handleScan(e.target.value, 'barcode-one')}
                            required={true}
                            value={this.state.barcodeOne}
                            type="text"
                            autoComplete="new-password"
                            data-form-type="other"
                            data-lpignore="true"
                        />
                    </Col>

                    {selectedShipment && (
                        <Col sm={4}>
                            {bagInputs}
                        </Col>
                    )}
                </Row>

                <Row style={{ justifyContent: "center", display: "flex", paddingTop: '1rem' }}>
                    <Col sm={4}>
                        <form onSubmit={this.fulfillShipment}>
                            <LoaderButton
                                type="submit"
                                isLoading={loading}
                                disabled={this.validateBarcode() !== false}
                                text="CONFIRM CUSTOMER COLLECTED"
                            />
                        </form>
                        <p>
                            Clicking confirm sends the customer an email confirmation.
                        </p>
                        <a onClick={this.resetScan}>
                            RESET
                        </a>
                    </Col>
                </Row>

                {selectedShipment && (
                    <Row style={{ justifyContent: "center", display: "flex" }}>
                        <Col sm={6}>
                            <p style={{ fontWeight: '700' }}>Items in order:</p>
                            {selectedShipment.items && selectedShipment.items.map((item, i) => 
                                item.availability === true && (
                                    <div key={i} style={{ marginBottom: '8px' }}>
                                        <p style={{ margin: '0', fontWeight: '500' }}>{item.name || item.product_name}</p>
                                        <p style={{ margin: '0', color: '#666' }}>Size: {item.size} | SKU: {item.sku}</p>
                                    </div>
                                )
                            )}
                        </Col>
                    </Row>
                )}
            </div>
        );
    };

    renderLoginView = () => (
        <div className="wrapper shipping-wrapper">
            <Row style={{ marginBottom: '10px' }}>
                <Col sm={12}>
                    <span style={{ fontSize: '18px', fontWeight: '700', textTransform: 'uppercase' }}>
                        Login in to view todays collections
                    </span>
                </Col>
            </Row>
            <Row>
                <TillLogin handleLogin={this.handleLogin} />
            </Row>
            <RealTimeAgent />
        </div>
    );

    render() {
        return (
            <>
                {this.props.loggedIn ? this.renderLoggedInView() : this.renderLoginView()}
                <div style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: '#00a63e',
                    color: 'white',
                    padding: '5px 10px',
                    borderRadius: '4px',
                    fontWeight: 'bold',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                    zIndex: 1000
                }}>NEW</div>
            </>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Collect));