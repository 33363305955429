import React, { Component } from "react";
import {connect} from "react-redux";
import ShipmentStageOneOld from "./components/ShipmentDropdownStageOne"
import ShipmentStageTwoOld from "./components/ShipmentDropdownStageTwo"
import {Col, Row} from "react-bootstrap";
import './components/shipmentdropdown.css'
import Popup from "./components/LabelPopup";

const mapDispatchToProps = dispatch =>{
    return {
    }
};

const mapStateToProps = state => {
    return {loading:state.shippingState.isLoading};
};



class ShippingDropDownComponentOld extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refresh: false,
            labelPopup:false
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if shipment has ibt attached, show it for user to print once as this will be a requirement for rejecting
        if((prevProps.shipment.ibt !== this.props.shipment.ibt) && this.props.shipment.ibt.length > 0){
            this.setState({labelPopup:true})
        }
    }

    handleLabelClose =()=>{
        this.setState({labelPopup:!this.state.labelPopup});
    }
    render() {
        let shipment = this.props.shipment;
        let stageofship = shipment.labelsPrinted  ? 'STAGE_THREE' :
            shipment.status ==="PENDING_PICKUP" ? 'STAGE_THREE' :
                shipment.stage ? shipment.stage : 'STAGE_ONE';
        let loading = this.props.loading;
        switch (stageofship) {
            case 'STAGE_ONE':
                if(shipment){
                    return (
                        <Row className={"dropdown-row"}>
                            <Col sm={12}>
                                    <ShipmentStageOneOld  onHold = {this.props.disabled} shipment={shipment} />
                            </Col>
                        </Row>
                    )
                }
            case 'STAGE_TWO':
            case 'STAGE_THREE':
                if(shipment){
                    return (
                                <Row className={"dropdown-row"}>
                                    {
                                        this.props.shipment.ibt && this.state.labelPopup ?
                                            <Popup
                                                handleClose = {this.handleLabelClose}
                                                content={
                                                    <iframe id={"labelprint"} type="application/pdf" name={"ibtprint"} width="100%" height="100%" src={this.props.shipment.ibt} style={{display:"block"}}/>
                                                }
                                            />
                                            :null
                                    }
                                    <Col sm={12}>
                                        <div>
                                            <ShipmentStageTwoOld labelsPrinted={!!shipment.labelsPrinted } shipment={shipment} stage={stageofship} collection={shipment.method === "Collect"}/>
                                        </div>
                                    </Col>
                                </Row>
                    )
                }
            case 'STAGE_COMPLETE':
                if(shipment && !loading){
                    return (
                        <div>
                            {
                                this.props.shipment.ibt && this.state.labelPopup ?
                                    <Popup
                                        handleClose = {this.handleLabelClose}
                                        content={
                                            <iframe id={"labelprint"} type="application/pdf" name={"ibtprint"} width="100%" height="100%" src={this.props.shipment.ibt} style={{display:"block"}}/>
                                        }
                                    />
                                    :null
                            }
                            <p>This order is now complete and will be removed from this list on refresh</p>
                        </div>
                    )
                }else {
                    return (
                        <div>
                            <div className="loading-spinner loading-spinner-blue"></div>
                        </div>
                    )
                }
        }


    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ShippingDropDownComponentOld)