import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import './reportshipping.css';

const mapStateToProps = state => ({
    userId: state.authState.username,
    role: state.authState.role,
    permissions: state.authState.userPermissions
});

class ReportingShippingNew extends Component {
    render() {
        // Use the store-specific URL from props, or fallback to the default URL
        const reportUrl = this.props.reportUrl || "https://lookerstudio.google.com/embed/reporting/4c13e640-5bbc-40e1-9a91-7afe4632363c/page/p_5dooay9agd";
        
        return (
            <div className="shipping-report">
                <Row>
                    <Col sm={3}>
                        <h1 className="heading-text" style={{ marginBottom: '20px' }}>Order Reporting</h1>
                    </Col>
                </Row>

                <div className="looker-report-container" style={{ width: '100%', height: 'calc(100vh - 100px)' }}>
                    <iframe 
                        src={reportUrl}
                        frameBorder="0"
                        style={{ width: '100%', height: '100%' }}
                        allowFullScreen
                    />
                </div>

                <div style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: '#00a63e',
                    color: 'white',
                    padding: '5px 10px',
                    borderRadius: '4px',
                    fontWeight: 'bold',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                    zIndex: 1000
                }}>NEW</div>
            </div>
        );
    }
}

const shippingReport = connect(mapStateToProps)(ReportingShippingNew);
export default shippingReport;