import React, {Component} from "react";
import {connect} from "react-redux";
import {getAllPickslips, getOrders, getPickslips, getShipments, updateFulfiller} from "../../actions/ShippingActions";
import {Row,Col} from "react-bootstrap";
import ReportingShipping from "./shipping/shippingReport";
import './reporting.css'
import {LinkContainer} from "react-router-bootstrap";
import ShippingIcon from "../../components/icons/icon-ship";
import OnboardingIcon from "../../components/icons/icon-tasks"
const mapStateToProps = state => {
};

const mapDispatchToProps = dispatch =>{
    return {
    }
};
class ReportingHomeNew extends Component {
    constructor(props) {
        super(props);
    };

    render(){
        return(
            <>
                <Row className ="reporting-wrapper">
                    <Col sm={12}>
                        <LinkContainer to="/reporting/shipping">
                            <div className ="reporting-icon">
                                <ShippingIcon className="icon"/>
                                <a style={{fontSize:'14px'}} className="label-text">Ship from store</a>
                            </div>
                        </LinkContainer>
                    </Col>
                </Row>
                <div style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: '#00a63e',
                    color: 'white',
                    padding: '5px 10px',
                    borderRadius: '4px',
                    fontWeight: 'bold',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                    zIndex: 1000
                }}>NEW</div>
            </>
        )
    }
}

const Reporting = connect(mapStateToProps, mapDispatchToProps)(ReportingHomeNew);
export default Reporting;