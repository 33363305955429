import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import {
    updateItemAvailability,
    updateShipmentStage,
    updateShipment,
    calculateLabels,
    generateCCLabel
} from "../../../actions/ShippingActionsNew";
import { connect } from "react-redux";
import LoaderButton from "../../../components/LoaderButton";
import { DndProvider } from "react-dnd";
import HTML5Backend from 'react-dnd-html5-backend';
import DroppableShipmentLabelNew from "./stagetwo/DroppableShipmentLabelNew";
import CustomDragLayer from "./stagetwo/CustomDragLayer";
import Checkbox from "../../../components/form/inputs/Checkbox";
import Popup from "./LabelPopup";
import METHOD from "../../../constants/shipping/MethodTypesNew";
import moment from 'moment-timezone';

const mapStateToProps = state => ({
    loading: state.shippingState.isLoading,
    focusedShipment: state.shippingState.focusedShipment
});

const mapDispatchToProps = dispatch => ({
    updateShipment: (shipmentId, status, reason, collection) => 
        dispatch(updateShipment(shipmentId, status, reason, collection)),
    updateItemAvailability: (shipmentId, itemId, availability) => 
        dispatch(updateItemAvailability(shipmentId, itemId, availability)),
    updateShipmentStage: (stage, shipmentId) => 
        dispatch(updateShipmentStage(stage, shipmentId)),
    calculateLabels: (shipmentId, labels, createdAt) => 
        dispatch(calculateLabels(shipmentId, labels, createdAt)),
    generateCCLabel: (shipment, parcels) => 
        dispatch(generateCCLabel(shipment, parcels))
});

class ShipmentStageTwoNew extends Component {
    constructor(props) {
        super(props);
        this.updateShipmentStatus = this.props.updateShipmentStatus;
        this.updateItemAvailability = this.props.updateItemAvailability;
        this.state = {
            labels: [],
            selectedParcel: 1,
            updateConfirmation: false,
            labelPopup: false
        };
    }

    componentDidMount() {
        this.prepareLabels();
    }

    componentDidUpdate(prevProps) {
        const { shipment } = this.props;
        if (prevProps.shipment.label !== shipment.label && shipment.label?.length > 0) {
            this.setState({ labelPopup: true });
        }
    }

    prepareLabels = () => {
        const { shipment } = this.props;
        const items = shipment.items.filter(x => x.availability === "true" || x.availability === true);

        const labelOne = {
            parcelType: 9,
            items: [...items],
            labelId: "1"
        };

        if (this.state.labels.length < 1) {
            this.setState({ labels: [labelOne] });
        }
    }

    updateLabels = (newLabels) => {
        if (!newLabels) return;

        const localLabels = this.state.labels.map(label => {
            const matchingLabel = newLabels.find(newLabel => newLabel.labelId === label.labelId);
            if (matchingLabel) {
                return { ...label, labelId: matchingLabel.auspostLabel };
            }
            return label;
        });

        this.setState({ labels: localLabels });
    }

    addLabel = () => {
        const label = {
            parcelType: 1,
            labelId: this.state.labels.length + 1 // TODO: will retrieve from magento 2
        };

        this.setState(prevState => ({
            labels: [...prevState.labels, label]
        }));
    }

    removeLabel = (labelId) => {
        this.setState(prevState => ({
            labels: prevState.labels.filter(x => x.labelId !== labelId)
        }));
    }

    changeParcelType = (index, labelId) => {
        this.setState(prevState => ({
            labels: prevState.labels.map(label => 
                label.labelId === labelId ? { ...label, parcelType: index } : label
            )
        }));
    }

    renderLabel = (label, callback, disableDrag, removeLabel, parcel, selectedParcel, collection, brand) => (
        <DroppableShipmentLabelNew 
            label={label} 
            change={callback} 
            disableDrag={disableDrag}
            remove={removeLabel} 
            parcel={parcel} 
            selectedParcel={selectedParcel}
            collection={collection}
            brand={brand}
        />
    )

    editLabel = (labelId, item) => {
        this.setState(prevState => {
            const updatedLabels = prevState.labels.map(label => {
                if (label.labelId === item.labelId) {
                    return {
                        ...label,
                        items: label.items.filter(x => x.shipmentItemId !== item.item.shipmentItemId)
                    };
                }
                if (label.labelId === labelId.labelId) {
                    const newItems = label.items ? [...label.items] : [];
                    newItems.push(item.item);
                    return {
                        ...label,
                        items: newItems
                    };
                }
                return label;
            });
            return { labels: updatedLabels };
        });
    }

    updateConfirmation = () => {
        this.setState(prevState => ({ 
            updateConfirmation: !prevState.updateConfirmation 
        }));
    }

    submitBack = (event) => {
        event.preventDefault();
        const { shipment, updateShipmentStage } = this.props;
        const { stage, shipmentId } = shipment;

        if (stage === "STAGE_THREE") {
            updateShipmentStage('STAGE_TWO', shipmentId);
        } else if (stage === "STAGE_TWO") {
            updateShipmentStage('STAGE_ONE', shipmentId);
        }
    }

    submitStepTwo = (event) => {
        event.preventDefault();
        const { collection, shipment, generateCCLabel, calculateLabels } = this.props;
        const { labels } = this.state;

        if (collection) {
            // If click and collect, run a separate label generation
            // generateCCLabel(shipment, labels);
            calculateLabels(shipment.shipmentId, labels, shipment.createdAt);
        } else {
            calculateLabels(shipment.shipmentId, labels, shipment.createdAt);
        }
    }

    submitStepThree = (event) => {
        event.preventDefault();
        const { updateShipmentStage, updateShipment, collection, shipment } = this.props;
        const { shipmentId } = shipment;

        updateShipmentStage('STAGE_COMPLETE', shipmentId);

        if (collection) {
            updateShipment(shipmentId, 'FULFILLED', false, true);
        } else {
            updateShipment(shipmentId, 'COMPLETE');
        }
    }

    formatDateTime = (date) => {
        const format = 'DD/MM/YYYY hh:mm A';
        return moment.tz(date, 'Australia/Brisbane').format(format);
    }

    handleLabelClose = () => {
        this.setState(prevState => ({ 
            labelPopup: !prevState.labelPopup 
        }));
    }

    render() {
        const { labels, updateConfirmation } = this.state;
        const { stage, shipment, collection, loading } = this.props;
        const { customerData, status, method } = shipment;
        const disableDrag = stage === 'STAGE_THREE';

        return (
            <div>
                {/* Label Preview Popup */}
                {/* {shipment.label && this.state.labelPopup && (
                    <Popup
                        handleClose={this.handleLabelClose}
                        content={
                            <iframe 
                                id="labelprint"
                                type="application/pdf"
                                name="labelprint"
                                width="100%"
                                height="100%"
                                src={shipment.label}
                                style={{ display: "block" }}
                            />
                        }
                    />
                )} */}

                {/* Labels Section */}
                <Row className="dynamic-label-row">
                    {/* Render Labels */}
                    {labels.length > 0 ? (
                        labels.map((label, i) => (
                            <DndProvider backend={HTML5Backend} key={i}>
                                <Col sm={5}>
                                    {this.renderLabel(
                                        label,
                                        this.editLabel,
                                        disableDrag,
                                        this.removeLabel,
                                        this.changeParcelType,
                                        label.parcelType,
                                        collection,
                                        shipment.brand
                                    )}
                                    <CustomDragLayer />
                                </Col>
                            </DndProvider>
                        ))
                    ) : (
                        <p>Loading label...</p>
                    )}

                    {/* Add Label Button or Customer Info */}
                    {!disableDrag ? (
                        <Col sm={3}>
                            <LoaderButton
                                variant="dark"
                                onClick={this.addLabel}
                                className="shipment-label-button btn-block"
                                block bsSize="large"
                                logo="fal fa-plus-circle"
                                text="ADD ANOTHER PARCEL"
                            />
                        </Col>
                    ) : shipment.customerName && method === METHOD.CLICKCOLLECT ? (
                        <Col sm={3}>
                            <div className="shipment-label-complete">
                                <p style={{ fontSize: "30px", textTransform: "uppercase" }}>
                                    {`${shipment.customerName}`}
                                </p>
                                <p style={{ fontSize: "24px", margin: 0 }}>Ready for pick-up:</p>
                                <p style={{ fontSize: "24px", lineHeight: "0.5em" }}>
                                    {shipment.fulfilledAt !== "pending" ? 
                                        this.formatDateTime(shipment.fulfilledAt) : "NOW"}
                                </p>
                                <p style={{ fontSize: "24px", margin: 0 }}>Order date:</p>
                                <p style={{ fontSize: "24px", lineHeight: "0.5em" }}>
                                    {this.formatDateTime(shipment.orderDate)}
                                </p>
                                <p style={{ fontSize: "22px", textTransform: 'uppercase' }}>Shipment ID: {(shipment.shipmentId).substring(0, 13)}</p>
                            </div>
                        </Col>
                    ) : null}
                </Row>

                {/* Confirmation Checkbox Section */}
                {stage === "STAGE_THREE" && status !== "FULFILLED" && (
                    <div>
                        <Row>
                            <Col sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Checkbox
                                    id="shipment-order-confirmation"
                                    onChange={this.updateConfirmation}
                                    checked={updateConfirmation}
                                    text="This order is now packed, labelled and waiting for collection"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p style={{ color: "red", fontSize: '32px' }}>
                                Please only use the most recently printed label on the parcel
                            </p>
                        </Row>
                    </div>
                )}

                {/* Action Buttons Section */}
                {(status === "FULFILLED" && loading) || status === "OPEN" || status === "COMPLETE_ERROR" ? (
                    <Row className="shipment-order-actions">
                        {!shipment.labelsPrinted && (
                            <Col sm={6}>
                                <form onSubmit={this.submitBack}>
                                    <LoaderButton
                                        variant="dark"
                                        className="shipment-order-button btn-block"
                                        block bsSize="large"
                                        type="submit"
                                        disabled={status === "FULFILLED"}
                                        text="GO BACK"
                                    />
                                </form>
                            </Col>
                        )}
                        <Col sm={6}>
                            <form onSubmit={stage === "STAGE_TWO" ? this.submitStepTwo : this.submitStepThree}>
                                <LoaderButton
                                    variant="success"
                                    className="shipment-order-button btn-block"
                                    block bsSize="large"
                                    type="submit"
                                    isLoading={loading}
                                    disabled={status === "FULFILLED" || (stage === "STAGE_THREE" && !updateConfirmation)}
                                    text={stage === "STAGE_TWO" ? "PRINT LABEL" : stage === "STAGE_COMPLETE" ? "COMPLETED" : "COMPLETE SHIPMENT"}
                                />
                            </form>
                        </Col>
                    </Row>
                ) : null}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentStageTwoNew);