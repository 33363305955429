const OPEN = 'OPEN';
const FULFILLED = 'FULFILLED';
const PENDING = 'PENDING';
const PENDING_PICKUP = 'PENDING_PICKUP';
const COMPLETE = 'COMPLETE';
const REJECTED = 'REJECTED';
const COMPLETE_ERROR = 'COMPLETE_ERROR';
const REJECTED_ERROR = 'REJECTED_ERROR';
const HOLD = 'HOLD';
const HOLD_WEIGHT = 'HOLD_WEIGHT';

export default {OPEN, FULFILLED, PENDING, PENDING_PICKUP, COMPLETE, REJECTED, COMPLETE_ERROR, REJECTED_ERROR, HOLD, HOLD_WEIGHT}