import * as type from "../constants/actions/ShippingActionTypes";
import config from '../config'
const initialState = {
    currentStore: null,
    reload: true, // this will be false if a new shipment has been added to the state or if any changes have been made to the shipmentState, to make sure re-renders do not occur
    isLoading:false, // loading indicators for frontend use
    allShipments:[],
    shipments: [],
    focusedShipment: {},
    token: '',
    stage: 'STAGE_ONE',
    searchedShipments:[],
    fulfiller: {},
    pendingOverview:{},
    manifest: null,
    auspost: null,
    loggedIn: false
};
export default function shippingReducer (state = initialState , action)  {
    switch (action.type) {
        case type.LOG_IN:
            return {...state,loggedIn:action.payload};
        case type.GET_AUTHENTICATION_TOKEN:
            return {...state, token: action.payload};
        case type.LOADING_SHIPMENTS:
            return {...state, isLoading:action.loading};
        case type.FETCH_SHIPMENTS:
            return {...state, stores:[],reload:false};
        case type.CLEAR_SHIPMENTS:
            return {...state,shipments:[]};
        case type.SET_AUSPOST_DETAILS:
            return {...state,auspost:action.payload};
        case type.SET_FOCUSED_SHIPMENT:
            return { ...state, focusedShipment: action.payload };
        case type.SET_SEARCHED_SHIPMENT:
            return { ...state, searchedShipments: action.payload };
        case type.CLEAR_SEARCHED_SHIPMENT:
            return { ...state, searchedShipments: action.payload };
        case type.ADD_SHIPMENTS:
            return {...state, shipments:action.payload,allShipments: action.payload};
        case type.UPDATE_FULFILLER:
            return {...state, fulfiller: action.payload };
        case type.UPDATE_SHIPMENTS_WITH_FULFILLER:
            return {
                ...state,
                shipments:state.shipments.map((shipment)=>(
                    {...shipment,fulfiller:action.payload})
                )
            };
        case type.ADD_NEW_SHIPMENT:
            return {...state, shipments: state.shipments.concat(action.shipment)};
        case type.UPDATE_ITEM_AVAILABILITY:
            return {
                ...state,
                shipments: state.shipments.map((shipment)=>(
                    shipment.shipmentId === action.shipmentId ? {...shipment, items: shipment.items.map((item)=>(
                        (item.shipmentItemId && item.shipmentItemId === action.itemId) || 
                        (item.localId === action.itemId) ? 
                            {...item, availability:action.avail, reason:action.reason} : item)
                    )}:shipment
                ))
            };
        case type.UPDATE_SHIPMENT_STAGE:
            return {
                ...state,
                shipments:state.shipments.map((shipment)=>(
                    shipment.shipmentId === action.shipmentId ? {...shipment,stage:action.payload} :shipment)
                )
            };
        case type.UPDATE_SHIPMENT_STATUS:
            return {
                ...state,
                shipments:state.shipments.map((shipment)=>(
                    shipment.shipmentId === action.shipmentId ? {...shipment,status:action.status} :shipment)
                )
            };
        case type.SET_AUSPOST_LABEL:
            return {
                ...state,
                shipments: state.shipments.map((shipment) => (
                    shipment.shipmentId === action.shipmentId ? {...shipment, label: action.payload}: shipment
                ))
            };
        case type.SET_REJECTED_IBT:
            return {
                ...state,
                shipments: state.shipments.map((shipment) => (
                    shipment.shipmentId === action.shipmentId ? {...shipment, ibt: action.payload}: shipment
                ))
            };
        case type.UPDATE_SHIPMENT:
            return {
                ...state,
                shipments: state.shipments.map((shipment) => (
                    shipment.shipmentId === action.shipment.shipmentId ? action.shipment : shipment
                ))
            }
        case type.SET_MANIFEST:
            return {...state, manifest: action.payload };
        case type.PENDING_OVERVIEW:
            return {...state, pendingOverview: action.payload};
        case type.REMOVE_SHIPMENT:
            return {
                ...state,
                shipments: state.shipments.filter(shipment => shipment.shipmentId !== action.shipment.shipmentId)
            };
        case type.UPDATE_ITEM_IMAGES:
            const updated_shipments = state.shipments.map( shipment => {
                shipment.items = shipment.items.map( item => {
                    item.media = action.images[item.group_number] !== undefined ? `${config.magento.baseUrl}media/catalog/product${action.images[item.group_number]}` : item.media;
                    return item;
                })
                return shipment;
            })
            return {
                ...state,
                shipments: updated_shipments
            };
        default:
            return state;
    }
};

