import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { updateShipmentStatus, updateItemAvailability, updateShipmentStage, updateShipment } from "../../../actions/ShippingActionsNew";
import { connect } from "react-redux";

import LoaderButton from "../../../components/LoaderButton";
import Select from "../../../components/form/inputs/Select";
import Checkbox from "../../../components/form/inputs/Checkbox";

import StockChecker from "./TickButton";
import config from "../../../config";
import METHOD from "../../../constants/shipping/MethodTypesNew";

const mapStateToProps = state => ({
    loading: state.shippingState.isLoading,
    shipments: state.shippingState.shipments
});

const mapDispatchToProps = dispatch => ({
    updateShipment: (shipmentId, status, reason) => dispatch(updateShipment(shipmentId, status, reason)),
    updateShipmentStatus: (shipmentId, itemId, status) => dispatch(updateShipmentStatus(shipmentId, itemId, status)),
    updateItemAvailability: (shipmentId, itemId, availability, reason) => dispatch(updateItemAvailability(shipmentId, itemId, availability, reason)),
    updateShipmentStage: (stage, shipmentId) => dispatch(updateShipmentStage(stage, shipmentId))
});

class ShipmentStageOneNew extends Component {
    constructor(props) {
        super(props);
        this.updateShipmentStatus = this.props.updateShipmentStatus;
        this.updateItemAvailability = this.props.updateItemAvailability;
        this.state = {
            options: [
                { value: 'out_of_stock', label: "Out of stock" },
                { value: 'faulty', label: "Faulty" }
            ],
            itemsToFulfill: this.props.shipment.items.length,
            selectedReason: null,
            reason: null,
            updateConfirmation: false,
            shipmentId: this.props.shipment.shipmentId,
            reject: false,
        };

        this.submitStepOne = this.submitStepOne.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.shipment.items !== this.props.shipment.items) {
            const allItemsUnavailable = this.props.shipment.items.every(item => item.availability === false);

            if (allItemsUnavailable && this.props.shipment.method === METHOD.CLICKCOLLECT) {
                this.setState({
                    reject: true,
                    itemsToFulfill: this.props.shipment.items.length
                });
            }

            const reject = this.checkItemReasons(this.props.shipment);
            this.setState({ reject });
        }
    }

    checkItemReasons = (shipment) => {
        let rejections = 0;
        let reasons = 0;

        shipment.items.forEach(item => {
            if (item.availability === false) {
                rejections++;
                if (item.reason) {
                    reasons++;
                }
            }
        });

        return rejections === reasons && (rejections !== 0 && reasons !== 0);
    };

    updateInStock = (shipmentId, itemId, avail, reason = null) => {
        if (avail === false) {
            this.updateItemAvailability(shipmentId, itemId, false, reason);

            if (reason === null) {
                // If click and collect order, ignore rejection, but still set item to be false
                if (this.props.shipment.method === METHOD.CLICKCOLLECT && this.props.shipment.items.length > 1) {
                    this.setState({ 
                        itemsToFulfill: this.state.itemsToFulfill - 1,
                        reject: false 
                    });
                } else if (this.state.itemsToFulfill < this.props.shipment.items.length) {
                    this.setState({ itemsToFulfill: this.state.itemsToFulfill + 1 });
                }
            }

            this.setState({ reason });
        } else if (avail === true) {
            if (this.state.itemsToFulfill - 1 === 0) {
                this.setState({ reject: false });
            } else if (this.props.shipment.method === METHOD.CLICKCOLLECT && this.props.shipment.items.length > 1) {
                this.setState({ reject: false });
            }

            this.updateItemAvailability(shipmentId, itemId, true);
            this.setState({ itemsToFulfill: this.state.itemsToFulfill - 1 });
        }
    }

    updateReason = (reason) => {
        this.setState({ selectedReason: reason, reason: reason.label });
    }

    updateConfirmation = (e) => {
        this.setState(prevState => ({ 
            updateConfirmation: !prevState.updateConfirmation 
        }));
    }

    submitStepOne = (event) => {
        event.preventDefault();
        console.log('[DEBUG] submitStepOne called');

        const shipmentId = this.props.shipment.shipmentId;
        console.log('[DEBUG] Processing shipment ID:', shipmentId);

        // Find which items are rejected with reasons and which are to be fulfilled
        const rejectedItems = this.props.shipment.items.filter(item => 
            item.availability === false && item.reason
        );
        console.log('[DEBUG] Rejected items:', rejectedItems.length, rejectedItems);

        const itemsToFulfill = this.props.shipment.items.filter(item => 
            item.availability === true
        );
        console.log('[DEBUG] Items to fulfill:', itemsToFulfill.length, itemsToFulfill);

        // Process rejections if any exist
        if (rejectedItems.length > 0) {
            console.log('[DEBUG] Processing rejected items - calling updateShipment with status REJECTED');
            this.props.updateShipment(shipmentId, 'REJECTED');
            this.props.updateShipmentStage('STAGE_COMPLETE', shipmentId);
            return;
        }

        // Only proceed to fulfillment if there are no rejected items
        // Determine next stage based on fulfillable items
        if (itemsToFulfill.length > 0) {
            console.log('[DEBUG] Items to fulfill exist - moving to STAGE_TWO');
            this.props.updateShipmentStage('STAGE_TWO', shipmentId);
        } else {
            console.log('[DEBUG] No items to fulfill - completing shipment with STAGE_COMPLETE');
            this.props.updateShipmentStage('STAGE_COMPLETE', shipmentId);
        }
    };

    render() {
        const { shipment } = this.props;
        const shipmentId = shipment.shipmentId;
        const { itemsToFulfill, updateConfirmation, reject } = this.state;

        // Determine which CDN to use based on shipment brand
        const cdnUrl = shipment.brand 
            ? config.shopifyCdn[shipment.brand.toLowerCase()] 
            : config.shopifyCdn.us;

        return (
            <div>
                <Row className="shipment-order-table">
                    {shipment.items.map((item, i) => (
                        <Col sm={4} key={item.sku + i}>
                            <div className="shipment-order-table-item-wrapper">
                                <Row>
                                    <div className="shipment-order-table-item">
                                        <Col sm={5} className="item-image">
                                            <img 
                                                src={item.imageUrl ? cdnUrl + '/' + item.imageUrl.split('/').pop() : ''} 
                                                alt={item.name} 
                                                style={{ width: "135px", maxWidth: "100%", height: "auto" }} 
                                            />
                                        </Col>
                                        <Col sm={7} className="item-info">
                                            <Row>
                                                <Col sm={12}>
                                                    <span style={{ fontWeight: "700" }}>ITEM: </span>
                                                    <span>{item.name}</span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <span style={{ fontWeight: "700" }}>SIZE: </span>
                                                    <span>{item.size}</span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <span style={{ fontWeight: "700" }}>SKU: </span>
                                                    <span>{item.sku}</span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} className="item-check">
                                                    <StockChecker 
                                                        runFunction={(e) => this.updateInStock(shipmentId, item.shipmentItemId, e)} 
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </div>
                                </Row>
                                {item.availability === false && (
                                    <Row>
                                        <Col sm={12}>
                                            <div className="shipment-order-table-item">
                                                <Select
                                                    id="shipment-order-reason"
                                                    options={this.state.options}
                                                    onChange={(e) => this.updateInStock(shipmentId, item.shipmentItemId, false, e.value)}
                                                    required={true}
                                                    selected={this.state.selectedReason}
                                                    placeholder={<div>Reason you can not ship item</div>}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        </Col>
                    ))}
                </Row>

                {itemsToFulfill < 1 || (shipment.method === METHOD.CLICKCOLLECT && itemsToFulfill < shipment.items.length) ? (
                    <form onSubmit={this.submitStepOne}>
                        <div className="shipment-order-actions-wrapper">
                            <Row className="shipment-order-actions">
                                <Col sm={5}>
                                    <Checkbox
                                        id="shipment-order-confirmation"
                                        onChange={(e) => this.updateConfirmation(e)}
                                        checked={updateConfirmation}
                                        text="I CONFIRM WE HAVE THESE ITEM/S" 
                                    />
                                </Col>
                                <Col sm={5}>
                                    <LoaderButton
                                        variant="success"
                                        className="shipment-order-button"
                                        block bsSize="large"
                                        type="submit"
                                        isLoading={this.props.loading}
                                        disabled={!((itemsToFulfill < 1 && updateConfirmation) && !this.props.onHold)}
                                        text="PACK ORDER"
                                    >
                                        FULFILL ORDER
                                    </LoaderButton>
                                </Col>
                            </Row>
                        </div>
                    </form>
                ) : reject ? (
                    <form onSubmit={this.submitStepOne}>
                        <Row className="shipment-order-actions">
                            <Col sm={5}>
                                <LoaderButton
                                    variant="success"
                                    className="shipment-order-button"
                                    block bsSize="large"
                                    type="submit"
                                    isLoading={this.props.loading}
                                    disabled={itemsToFulfill === 0 || this.state.reason === null}
                                    text="REJECT ORDER"
                                />
                            </Col>
                        </Row>
                    </form>
                ) : null}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentStageOneNew);

