import {getShipments, getFocusedShipment, updateFulfiller, getPickslips} from "../../actions/ShippingActionsNew";
import React, {Component} from "react";
import {connect} from "react-redux";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody} from "react-accessible-accordion";
import ShippingDropDownComponentNew from "./ShippingDropDownComponentNew";
import PrintIcon from "../../components/icons/icon-print"
import "./components/shippingaccordion.css"
import CollectionTimer from "./components/Timer"
import {Storage} from "aws-amplify/lib";

//Shipment Constants
import METHODS from "../../constants/shipping/MethodTypesNew";
import STATUS from "../../constants/shipping/StatusTypesNew";
import CARRIER from "../../constants/shipping/CarrierTypesNew";
import STORES from "../../constants/shipping/StoreTypes";

//Carrier Logos
import AramexLogo from "../../components/icons/carriers/aramex";
import AuspostLogo from "../../components/icons/carriers/auspost";
import SherpaLogo from "../../components/icons/carriers/sherpa";
import ClickCollectIcon from "../../components/icons/carriers/clickcollect";

//Store Logos
import UniversalStoreStackedLogo from "../../components/icons/stores/universal-store-stacked";
import PerfectStrangerStackedLogo from "../../components/icons/stores/perfect-stranger-stacked";

//Logos
import DownArrow from "../../images/arrow.png"
import PendingIcon from "../../images/pending-icon.svg"
import CompletedIcon from "../../images/completed-icon.svg"

var fileDownload = require('js-file-download');

const mapStateToProps = state => ({
    user: state.authState,
    shipments: state.shippingState.shipments,
    searchedShipments: state.shippingState.searchedShipments,
    loading: state.shippingState.isLoading,
    focusedShipment: state.shippingState.focusedShipment
});

const mapDispatchToProps = dispatch => ({
    getShipments: () => dispatch(getShipments()),
    updateFulfiller: (fulfiller) => dispatch(updateFulfiller(fulfiller)),
    getFocusedShipment: (shipment) => dispatch(getFocusedShipment(shipment)),
    getPickslips: (shipmentId) => dispatch(getPickslips(shipmentId))
});

class ShippingListNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shipmentAlerts: [],
            stage: null
        };

        // Bind methods in constructor
        this.handleLabelDownload = this.handleLabelDownload.bind(this);
        this.buildShipmentInformation = this.buildShipmentInformation.bind(this);
        this.handleAccordionChange = this.handleAccordionChange.bind(this);
    }

    componentDidMount() {
        this.props.getShipments();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.shipments !== nextProps.shipments ||
            this.props.loading !== nextProps.loading ||
            this.props.focusedShipment !== nextProps.focusedShipment ||
            this.state.shipmentAlerts !== nextState.shipmentAlerts
        );
    }

    async handleLabelDownload(name) {
        try {
            const result = await Storage.get('labels/' + name + '-label.pdf', {download: true});
            fileDownload(result.Body, name + '-label.pdf');
        } catch (err) {
            console.error('Error downloading label:', err);
        }
    }

    handleTimerAlert = async (shipmentId, alert) => {
        let index = this.state.shipmentAlerts.findIndex(x => x.id === shipmentId);
        if (index === -1) {
            let joined = this.state.shipmentAlerts.concat({id: shipmentId, alert: alert});
            this.setState({shipmentAlerts: joined})
        }
    };

    isDisabled(status) {
        let disabledStatus = [
            STATUS.COMPLETE,
            STATUS.REJECTED,
            STATUS.HOLD,
            STATUS.HOLD_WEIGHT,
            STATUS.FULFILLED
        ]

        return disabledStatus.includes(status);
    }

    /**
     * Determine which carrier logo to render based on the 'carrier' attached to the shipment. If the carrier
     * doesn't exist (legacy shipment) render the carrier logo based on the shipping method.
     * @param shipment
     * @returns {JSX.Element|string}
     */
    renderCarrierLogo(shipment) {
        let carrier = shipment.carrier;
        switch (carrier) {
            case 'AUSPOST':
                return <AuspostLogo/>;
            case 'ARAMEX':
                return <AramexLogo/>;
            case 'SHERPA':
                return <SherpaLogo/>;
            case 'CLICKCOLLECT':
                return <ClickCollectIcon/>;
            default:
                return 'Unknown Carrier';
        }
    }

    /**
     * Determine if the shipment needs the 'urgent' class for the carrier column dependant on the carrier type
     *
     * @param carrier
     * @param method
     * @returns {string|string}
     */
    determineCarrierUrgency(carrier, shipping_method) {
        switch (carrier) {
            case 'SHERPA':
            case 'CLICKCOLLECT':
                return 'urgent';
            case 'AUSPOST':
                return shipping_method === METHODS.EXPRESS ? 'urgent' : '';
            case 'ARAMEX':
                return shipping_method === METHODS.EXPRESS ? 'urgent' : '';
            default:
                return '';
        }
    }

    /**
     * Determine if the shipment needs the 'urgent' class for the shipping method column based on the shipping method.
     * @param method
     * @returns {string}
     */
    determineShippingUrgency(method) {
        return (method === METHODS.CLICKCOLLECT || method === METHODS.SAME_DAY) ? 'urgent' : '';
    }

    /**
     * Return the title for the shipment based on the carrier and shipping method combination.
     *
     * @param shipment
     * @returns {string}
     */
    determineCarrierTitle(shipment) {
        const { carrier, method } = shipment;

        // Handle Click & Collect cases first
        if (method === METHODS.CLICKCOLLECT || carrier === CARRIER.CLICKCOLLECT) {
            return 'Click And Collect';
        }

        // Handle carrier + method combinations
        switch (carrier) {
            case CARRIER.ARAMEX:
                return 'Aramex Shipping';
            case CARRIER.AUSPOST:
                return method === METHODS.EXPRESS ? 'Auspost Express Shipping' : 'Auspost Standard Shipping';
            case CARRIER.SHERPA:
                return 'Same Day Shipping';
            default:
                return 'Unknown Shipping Method';
        }
    }

    /**
     * Determine which store logo to render based on the given store.
     *
     * @param onlineStoreId
     * @returns {JSX.Element}
     */
    renderStoreLogo(brand) {
        switch (brand) {
            case 'US':
                return <UniversalStoreStackedLogo/>;
            case 'PS':
                return <PerfectStrangerStackedLogo/>;
            default:
                return <UniversalStoreStackedLogo/>;
        }
    }

    /**
     * Determine the status information to display in the status column.
     *
     * @param isLabelPrinted
     * @param status
     * @returns {JSX.Element}
     */
    determineStatus(isLabelPrinted, status) {
        if (isLabelPrinted && status !== STATUS.COMPLETE) {
            return <p style={{fontWeight: '700', textTransform: 'uppercase', textAlign: "center"}}>
                Label printed. Click 'Print Label' if you cannot find it</p>
        } else {
            let icon = (status === STATUS.COMPLETE || status === STATUS.FULFILLED) ? CompletedIcon : PendingIcon
            return <>
                <p style={{fontWeight: '700', textTransform: 'uppercase'}}>
                    {"   " + status === "HOLD"
                        ? "ON HOLD" : status === STATUS.FULFILLED
                            ? "WAITING FOR PICK-UP" : status ==="HOLD_WEIGHT"
                                ? "HOLD (Weight needed)" : status}
                </p>
                <img alt="status-icon" style={{width: "30px"}} src={icon}/>
            </>
        }
    }

    /**
     * Determine the print columns text and onclick functionality
     *
     * @param shipmentId
     * @param isLabelPrinted
     * @param status
     * @returns {JSX.Element}
     */
    determinePrintType(shipmentId, isLabelPrinted, status) {
        let labelExists = !!isLabelPrinted || status === STATUS.COMPLETE;
        let downloadHandler = labelExists ?
            (async (e) => {
                await this.handleLabelDownload(shipmentId);
                e.stopPropagation();
            }) :
            ((e) => {
                // Open pickslip in new window
                this.props.getPickslips(shipmentId);
                e.stopPropagation();
            });

        return (
            <div onClick={downloadHandler} className={"left-accordion-header download-pickslip"}>
                <span>{labelExists ? 'PRINT LABEL' : 'PRINT PICKSLIP'}</span>
                <PrintIcon className="icon"/>
            </div>
        )
    }

    /**
     * Return a CollectionTimer component dependant on the shipment shipping method. Returns null if not applicable
     *
     * @param method
     * @param status
     * @param createdAt
     * @param shipmentId
     * @returns {JSX.Element|null}
     */
    determineCountdown({method, status, createdAt, shipmentId}) {
        if ((method === METHODS.CLICKCOLLECT || method === METHODS.SAME_DAY) && status === STATUS.FULFILLED) {
            return <CollectionTimer createdAt={createdAt} onTimerAlert={(e) => this.handleTimerAlert(shipmentId, e)}/>
        }
        return null;
    }

    // Use regular memoization pattern
    buildShipmentInformation(shipment) {
        return {
            storeLogo: this.renderStoreLogo(shipment.brand),
            carrierLogo: this.renderCarrierLogo(shipment),
            carrierTitle: this.determineCarrierTitle(shipment),
            status: this.determineStatus(shipment.labelsPrinted, shipment.status),
            print: this.determinePrintType(shipment.shipmentId, shipment.labelsPrinted, shipment.status),
            countdown: this.determineCountdown(shipment),
        }
    }

    // Cache alert lookup
    getShipmentAlert = (shipmentId) => {
        return this.state.shipmentAlerts.find(x => x.id === shipmentId)?.alert;
    }

    renderShippingHeader(shipment) {
        let disabled = this.isDisabled(shipment.status) ? "complete" : "";
        let printed = shipment.labelsPrinted && shipment.status !== STATUS.COMPLETE ? 'urgent' : '';
        let shipmentInformation = this.buildShipmentInformation(shipment);
        let alert = this.getShipmentAlert(shipment.shipmentId);

        return (
            <div
                className={"accordion-header-wrapper shipping-accordion-header-wrapper " + disabled + " " + printed + " " + alert}>
                <Row>
                    <Col sm={1} className={`carrier-column ${this.determineCarrierUrgency(shipment.carrier, shipment.method)}`}>
                        {shipmentInformation.carrierLogo}
                    </Col>
                    <Col sm={2} className={`store-column`}>
                        {shipmentInformation.storeLogo}
                    </Col>
                    <Col sm={3} className={"shipment-details-column"}>
                        <div className={`shipping-urgency ${this.determineShippingUrgency(shipment.method)}`} style={{fontWeight: '700', fontSize: '18px'}}>
                          {shipmentInformation.carrierTitle}
                        </div>
                        <div style={{fontWeight: '700', fontSize: '18px', textTransform: 'uppercase'}}>
                          SHIPMENT: {(shipment.shipmentId).substring(0, 13)}
                        </div>
                    </Col>
                    <Col sm={2} className={"timer-column"}>
                        {/* {shipmentInformation.countdown} */}
                    </Col>
                    <Col sm={1} className={"status-column"}>
                        {shipmentInformation.status}
                    </Col>
                    <Col sm={2} className={"print-column"}>
                        {shipmentInformation.print}
                    </Col>
                    <Col sm={1} className={"arrow-column"}>
                        <div className={"left-accordion-header"}>
                                <span className={"quarter arrow"}>
                                        <img style={{marginTop: '10px', marginBottom: '10px'}}
                                             className={"toggle-arrow"} src={DownArrow}/>
                                </span>
                        </div>
                    </Col>
                </Row>
                {this.props.focusedShipment === shipment.shipmentId && shipment.status === STATUS.PENDING ?
                    <Row className={"stages-wrapper"}>
                        <Col sm={4}>
                            <span style={shipment.stage ? shipment.stage !== "STAGE_ONE" ? {fontSize: 14 + 'px'} : {
                                fontWeight: 700,
                                fontSize: 14 + 'px'
                            } : {fontWeight: 700, fontSize: 14 + 'px'}}>
                                CONFIRM ORDER </span>
                        </Col>
                        <Col sm={4} style={shipment.stage ? shipment.stage === "STAGE_TWO" ? {
                            fontWeight: 700,
                            fontSize: 14 + 'px'
                        } : {fontSize: 14 + 'px'} : {fontSize: 14 + 'px'}}>
                            PACK ORDER
                        </Col>
                        <Col sm={4} style={shipment.stage ? shipment.stage === "STAGE_THREE" ? {
                            fontWeight: 700,
                            fontSize: 14 + 'px'
                        } : {fontSize: 14 + 'px'} : {fontSize: 14 + 'px'}}>
                            COMPLETE ORDER
                        </Col>
                    </Row>
                    : null
                }
            </div>
        )
    }

    handleAccordionChange(uuids) {
        // The accordion library passes an array of UUIDs when in accordion mode
        const uuid = Array.isArray(uuids) ? uuids[0] : uuids;

        // Only update if the focused shipment is different
        if (uuid !== this.props.focusedShipment) {
            this.props.getFocusedShipment(uuid);
        }
    }

    render() {
        return (
            (this.props.shipments.length > 0 ?
                <Accordion 
                    accordion={true}
                    onChange={this.handleAccordionChange}
                >
                    {this.props.shipments.map((shipment) => (
                        <AccordionItem
                            key={shipment.shipmentId}
                            uuid={shipment.shipmentId}
                            className="accordion-node"
                        >
                            <AccordionItemTitle 
                                style={{"padding": 0, "boxShadow": "none"}}
                                className="applicant-accordion-header"
                            >
                                {this.renderShippingHeader(shipment)}
                            </AccordionItemTitle>
                            <AccordionItemBody className="accordion-panel">
                                <ShippingDropDownComponentNew 
                                    disabled={this.isDisabled(shipment.status)} 
                                    shipment={shipment}
                                />
                            </AccordionItemBody>
                        </AccordionItem>
                    ))}
                </Accordion>
                : this.props.loading ? 
                    <div className="loading-spinner loading-spinner-blue" /> :
                    <div className="no-shipments-container" style={{ textAlign: 'center', padding: '50px 0' }}>
                        <h3>No Shipments for your store</h3>
                        <p>There are currently no pending shipments to fulfill.</p>
                    </div>
            )
        )
    }
}

const Shipping = connect(mapStateToProps, mapDispatchToProps)(ShippingListNew);
export default Shipping;