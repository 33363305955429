import React, {Component, useState,useCallback} from "react";
import {Row, Col} from "react-bootstrap";
import { useDrop } from 'react-dnd'
//import update from 'immutability-helper'
import DraggableShipmentItemNew from "./DraggableShipmentItemNew";
import {moveItem} from "./Actions";
import {Toggle} from "./ParcelSelect";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

function renderItem(item, key, labelId, disableDrag, brand){
    return <DraggableShipmentItemNew 
        item={item} 
        key={item.shipmentItemId} 
        itemKey={item.shipmentItemId} 
        labelId={labelId} 
        disableDrag={disableDrag} 
        brand={brand} 
    />
}

const mapStateToProps = state => {
    return {user: state.authState, store: state.authState.store};
};

const ShipmentLabelNew = ({store,label,children, onDrop, item, disableDrag,remove,parcel,selectedParcel,collection,brand})=>{
    let even = label.labelId  % 2 === 0 ? "red" : "";
    let cols = label.items;
    let rows = cols/3;

    return (
            <div key={label.labelId} >
                <div className={"shipment-label-table "+even}>
                    <div className={"shipment-label-table-header "+even+" "+disableDrag}>
                        <Col sm={10}>
                            {
                                collection ?   <span className = {'parcel-label'}>UNIVERSAL STORE BAG #{label.labelId}</span>
                                    :  <span className = {'parcel-label'}>PARCEL LABEL #{label.labelId}</span>

                            }

                        </Col>
                        {
                            ((!label.items || label.items.length < 1) && label.labelId !==1 ) ?
                                <Col sm={1} >
                                    <i onClick={()=>remove(label.labelId)} className="fal fa-minus-circle" style={{fontSize:18+'px'}} />
                                </Col>
                                :
                                <Col sm={1} >
                                </Col>

                        }

                        {!disableDrag ?
                            <Col className={'parcel-col'} sm={12}>
                                <Toggle collection = {collection} store ={store} selectedItem={selectedParcel} onSelect={(e)=>parcel(e,label.labelId)} />
                            </Col>
                            :null
                        }

                    </div>
                        {(label.items && label.items.length > 0 ) ?

                            <Row className={"shipment-label-table-item"}>
                                {label.items.map(item => renderItem(item, item.shipmentItemId, label.labelId, disableDrag, brand))}
                            </Row>

                            :
                            <Row className={"shipment-label-table-item"}>
                            <Col sm={12}>
                            <span>
                            There are currently no items listed.
                            Move an item onto this parcel label.
                            </span>
                            </Col>
                            </Row>
                        }
                </div>
            </div>
    )
};


const shipmentLabel = connect(mapStateToProps, null)(ShipmentLabelNew);
export default shipmentLabel;
