import React,{Component} from "react";
import {Row, Col} from "react-bootstrap";
import {updateShipmentStatus, updateItemAvailability, updateShipmentStage, updateShipment} from "../../../actions/ShippingActions";
import {connect} from "react-redux";

import LoaderButton from "../../../components/LoaderButton";
import Select from "../../../components/form/inputs/Select";
import Checkbox from "../../../components/form/inputs/Checkbox";

import StockChecker from "./TickButton"
import Popup from "./LabelPopup";

const mapStateToProps = state => {
    return {loading:state.shippingState.isLoading,shipments:state.shippingState.shipments};
};

const mapDispatchToProps = dispatch =>{
    return {
        updateShipment : (shipmentId,status,reason) => {dispatch(updateShipment(shipmentId,status,reason))},
        updateShipmentStatus : (shipmentId,itemId,status) => {dispatch(updateShipmentStatus(shipmentId,itemId,status))},
        updateItemAvailability: (shipmentId,itemId,availability,reason) => {dispatch(updateItemAvailability(shipmentId,itemId,availability,reason))},
        updateShipmentStage : (stage,shipmentId) => {dispatch(updateShipmentStage(stage,shipmentId))}
    }
};

class ShipmentStageOne extends Component {
    constructor(props) {
        super(props);
        this.updateShipmentStatus = this.props.updateShipmentStatus;
        this.updateItemAvailability = this.props.updateItemAvailability;
        this.state = {
            options: [
                {value:'out_of_stock',label:"Out of stock"},
                {value:'on_hold',label:"On hold"},
                {value:'manufacturing_fault',label:"Manufacturing fault"},
                {value:'damaged_item_sell_in_store',label:"Damaged item"}],
            itemsToFulfill : this.props.shipment.items.length,
            selectedReason: null,
            reason: null,
            updateConfirmation: false,
            shipmentId:this.props.shipment.shipmentId,
            reject: false,

        };
    }
    componentDidUpdate(prevProps) {
        if(prevProps.shipment.items !== this.props.shipment.items){
            let isTrue = this.props.shipment.items.every(function (a) {
                return a.availability === false;
            });
            if(isTrue && this.props.shipment.method === "Collect"){
                this.setState({reject:true});
                this.setState({itemsToFulfill: this.props.shipment.items.length});
            }
            let reject = this.checkItemReasons(this.props.shipment);
            this.setState({reject});
        }

    }

    checkItemReasons = (shipment) => {
        let rejectedShipment = shipment;
        let rejections = 0;
        let reasons = 0;
        rejectedShipment.items.map((item)=>{
            if(item.availability === false ){
                rejections++;
                if(item.reason){
                    reasons ++;
                }
            }});
        return rejections === reasons && (rejections !== 0 && reasons !==0);
    };

    updateInStock(shipmentId,localId,avail,reason = null){
        console.log(shipmentId,localId,avail,reason)
        if(avail === false){
            this.updateItemAvailability(shipmentId,localId,false,reason);
            if(reason === null){
                //if click and collect order, ignore rejection, but still set item to be false
                if(this.props.shipment.method === "Collect" && this.props.shipment.items.length > 1){
                    this.setState({itemsToFulfill: this.state.itemsToFulfill-1});
                    this.setState({reject:false});
                }else if(this.state.itemsToFulfill < this.props.shipment.items.length) {
                    this.setState({itemsToFulfill: this.state.itemsToFulfill+1})
                }
            }
            this.setState({reason:reason});

        }else if(avail === true) {
            if(this.state.itemsToFulfill - 1 === 0 ){
                this.setState({reject:false});
            }else if(this.props.shipment.method === "Collect" && this.props.shipment.items.length > 1){
                this.setState({reject:false});
            }
            this.updateItemAvailability(shipmentId,localId,true);
            this.setState({itemsToFulfill: this.state.itemsToFulfill-1})
        }
    }

    updateReason(reason){
        this.setState({selectedReason:reason,reason:reason.label});
    }

    updateConfirmation(e){
        console.log(e);
        let confirmation = this.state.updateConfirmation;
        confirmation = !confirmation;
        this.setState({updateConfirmation:confirmation});
    }

    submitStepOne=(event)=>{
        event.preventDefault();
        let shipmentId = this.state.shipmentId;
        if(this.state.itemsToFulfill > 0) {
            let shipmentId = this.props.shipment.shipmentId;
            this.props.updateShipmentStage('STAGE_COMPLETE',shipmentId);
            this.props.updateShipment(shipmentId,'REJECTED');
        }else {
            this.props.updateShipmentStage('STAGE_TWO',shipmentId);
        }

    };




    render(){
        let shipment = this.props.shipment;
        let shipmentId = this.props.shipment.shipmentId;
        let l=this.state.itemsToFulfill > 0 && this.state.updateConfirmation === false;
        return (
            <div>

                <Row className={"shipment-order-table"}>
                  {
                      shipment.items.map((item,i)=>
                          <Col sm={4} key={item.sku+i}>
                              <div  className ={"shipment-order-table-item-wrapper"}>
                                  <Row>
                                      <div className={"shipment-order-table-item"}>
                                          <Col sm={5} className={"item-image"} >
                                              <img src={item.media} alt={<i className="fad fa-tshirt"/>}/>
                                          </Col>
                                          <Col sm={7}  className={"item-info"}>
                                              <Row>
                                                  <Col sm={12}>
                                                      <span style={{fontWeight:"700"}}> ITEM: </span> <span>{item.product_name}</span>
                                                  </Col>
                                              </Row>
                                              <Row>
                                                  <Col sm={12}>
                                                      <span style={{fontWeight:"700"}}>  SIZE: </span><span>{item.size}</span>
                                                  </Col>
                                              </Row>
                                              <Row>
                                                  <Col sm={12}>
                                                      <span style={{fontWeight:"700"}}>SKU: </span><span>{item.sku}</span>
                                                  </Col>
                                              </Row>
                                              <Row>
                                                  <Col sm={12} className={"item-check"}>
                                                      <StockChecker runFunction={(e)=>this.updateInStock(shipmentId,item.localId,e)}  />
                                                  </Col>
                                              </Row>
                                          </Col>
                                      </div>
                                  </Row>
                                  {item.availability === false ?
                                      <Row>
                                          <Col sm={12}>
                                              <div className={"shipment-order-table-item"}>
                                                  <Select
                                                      id="shipment-order-reason"
                                                      options={this.state.options}
                                                      onChange={(e) => this.updateInStock(shipmentId,item.localId,false,e.value)}
                                                      required={true}
                                                      selected ={this.state.selectedReason}
                                                      placeholder={<div>Reason you can not ship item</div>}
                                                  />
                                              </div>
                                          </Col>
                                      </Row>
                                       : null
                                  }

                              </div>
                          </Col>
                      )
                  }
            </Row>
                { this.state.itemsToFulfill < 1 || (this.props.shipment.method ==="Collect" && this.state.itemsToFulfill < this.props.shipment.items.length) ?
                    <form onSubmit={this.submitStepOne}>
                        <div className="shipment-order-actions-wrapper">
                            <Row className="shipment-order-actions">
                                <Col sm={5}>
                                    <Checkbox
                                        id="shipment-order-confirmation"
                                        onChange={(e)=>this.updateConfirmation(e)}
                                        checked={this.state.updateConfirmation}
                                        text={"I CONFIRM WE HAVE THESE ITEM/S"} />
                                </Col>
                                <Col sm={5}>
                                    <LoaderButton
                                        variant={"success"}
                                        className="shipment-order-button"
                                        block bsSize="large"
                                        type="submit"
                                        isLoading={this.props.loading}
                                        disabled={!((this.state.itemsToFulfill < 1 && this.state.updateConfirmation) && !this.props.onHold)}
                                        text="PACK ORDER">FULFILL ORDER
                                    </LoaderButton>
                                </Col>
                            </Row>
                        </div>
                    </form>
                    :this.state.reject === true ?
                        <form onSubmit={this.submitStepOne}>
                            <Row className="shipment-order-actions">
                                <Col sm={5}>
                                    <LoaderButton
                                        variant={"success"}
                                        className="shipment-order-button"
                                        block bsSize="large"
                                        type="submit"
                                        isLoading={this.props.loading}
                                        disabled = {this.state.itemsToFulfill === 0 || this.state.reason === null}
                                        text="REJECT ORDER"/>
                                </Col>
                            </Row>
                        </form>

                    : null
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentStageOne);
