import * as lambda from "./ShippingCallsNew"
import * as type from "../constants/actions/ShippingActionTypes";
import * as errorTypes from "../constants/actions/ShippingErrorTypes";
import * as store from "./StoreActions";
import * as cogLib from "../libs/cognitoLib"
import config from "../config";
import * as s3 from "../libs/awsLib";
import * as notification from './NotificationActions'
import * as jsPDF from 'jspdf';

//Shipment Constants
import METHOD from "../constants/shipping/MethodTypesNew";
import STATUS from "../constants/shipping/StatusTypesNew";
import { getProductsBySkus } from './catalog/CatalogCalls'

var moment = require('moment-timezone');

const loading = (loading) => {
    return (
        {
            type:type.LOADING_SHIPMENTS,
            loading:loading
        }
    )
};

export const loggedIn = (login) => {
    return (
        {
            type:type.LOG_IN,
            payload:login
        }
    )
};

const addShipment =  (shipments) => {
    return (
        {
            type:type.ADD_SHIPMENTS,
            payload:shipments
        }
    )
};

const clearShipments = ()=> {
    return (
        {
            type:type.CLEAR_SHIPMENTS
        }
    )
};

const setFocusedShipment = (shipment) => ({
    type: type.SET_FOCUSED_SHIPMENT,
    payload: shipment
});

const setAuspostInfo = (auspost) =>{
    return (
        {
            type:type.SET_AUSPOST_DETAILS,
            payload:auspost
        }
    )
};

const setLabels = (shipmentId,labels) => {
    return (
        {
            type:type.SET_AUSPOST_LABEL,
            shipmentId: shipmentId,
            payload:labels
        }
    )
}

const setIbt = (shipmentId,ibt) => {
    return (
        {
            type:type.SET_REJECTED_IBT,
            shipmentId: shipmentId,
            payload:ibt
        }
    )
}

const setManifest = (manifest) => {
    return (
        {
            type:type.SET_MANIFEST,
            payload:manifest
        }
    )
}

const addPendingOverview = (data) => {
    return (
        {
            type:type.PENDING_OVERVIEW,
            payload:data
        }
    )
}

const updateShipmentInView = (shipment) => {
    return (
        {
            type:type.UPDATE_SHIPMENT,
            shipment: shipment
        }
    )
}

const addNewShipmentToView = (shipment) => {
    return (
        {
            type:type.ADD_NEW_SHIPMENT,
            shipment: shipment
        }
    )
}

const removeShipmentFromView = (shipment) => {
    return (
        {
            type:type.REMOVE_SHIPMENT,
            shipment: shipment
        }
    )
}

/**
 * Using itemId to change item availability
 */
export function updateItemAvailability (shipmentId, itemId, availability, reason) {
    return (
        {
            type: type.UPDATE_ITEM_AVAILABILITY,
            shipmentId: shipmentId,
            itemId: itemId,  // Use itemId (shipmentItemId) instead of localId
            avail: availability,
            reason: reason ? reason : null
        }
    )
}

export function updateShipmentStatus(shipmentId,status){
    return (
        {
            type:type.UPDATE_SHIPMENT_STATUS,
            shipmentId:shipmentId,
            status:status,
        }
    )
}
export function updateShipmentStage(stage,shipmentId){
    return (
        {
            type:type.UPDATE_SHIPMENT_STAGE,
            payload:stage,
            shipmentId:shipmentId
        }
    )
}

export function updateFulfiller(fulfiller){
    return (
        {
            type:type.UPDATE_FULFILLER,
            payload:fulfiller
        }
    )
}

export function updateShipmentsWithFulfiller(fulfiller){
    return (
        {
            type:type.UPDATE_SHIPMENTS_WITH_FULFILLER,
            payload:fulfiller
        }
    )
}

export function updateProductImages(images) {
    return (
        {
            type:type.UPDATE_ITEM_IMAGES,
            images,
        }
    )
}

/*Fetch and Logic functions*/
 const fetchResponsibleShipments = (store) => {
  return () => {
      return lambda.getResponsibleShipments(store).then(response => {
          return response;
      })
    }
};

 const fetchSingleReponsibleShipment = (shipmentId)=>{
    return () => {
        return lambda.getSingleResponsibleShipment(shipmentId).then(response => {
            return response;
        })
    }
}

const fetchLabel = (shipmentId, packageComposition, shippingAddress, fulfiller, fulfilledAt) => {
    return() => {
        return lambda.getLabel(shipmentId, packageComposition, shippingAddress, fulfiller, fulfilledAt).then(response => {
            return response;
        }).catch({

        })
    }
};

const fetchManifest = (storeId) => {
    return() => {
        return lambda.getManifest(storeId).then(response => {
            return response;
        })
    }
};

const fetchPickslip = (shipmentId, store) => {
    return() => {
        return lambda.getPickslip(shipmentId, store).then(response => {
            return response;
        })
    }
};

const fetchCCLabel = (storeId, shipmentId, brand) => {
    return() => {
        return lambda.generateCCLabel(storeId, shipmentId, brand).then(response=>{
            return response;
        })
    }
};

const fetchAllPickslips = (store, type, web_store) => {
    return() => {
        return lambda.getAllPickslips(store, type, web_store).then(response => {
            return response;
        })
    }
};

const fulfillShipment = (storeId, shipmentToEdit, status, collection=false) =>{
    return() => {
        return lambda.fulfillShipment(storeId, shipmentToEdit, status, collection).then(response => {
            return response;
        })
    }
};

const fulfilShipment = (shipment,storeId,status) =>{
    return() => {
        return lambda.fulfilShipment(shipment,storeId,status).then(response => {
            return response;
        })
    }
};

const rejectShipment = (shipmentId, rejectedItems) => {
    return() => {
        return lambda.rejectShipment(shipmentId, rejectedItems).then(response => {
            return response;
        });
    };
};

const getFulfilledByIncrement = (incrementId,store) => {
    return () => {
        return lambda.queryFulfilledByIncrement(incrementId,store).then(response => {
            return response;
        })
    }
};

const fetchPendingDetails = (store,userId=null) => {
    return () => {
        return lambda.getPendingShipments(store,null,userId).then(response => {
            return response;
        })
    }
};

const fetchFulfillerData = (store,futuraId) => {
    return () => {
        return lambda.getFulfillerData(store,futuraId).then(response => {
            return response;
        })
    }
};

const setSearchedShipments = (shipments) => {
    return {
        type: type.SET_SEARCHED_SHIPMENT,
        payload: shipments
    }
};

/**
 *
 * @returns {function(*, *): *}
 */
export function getShipments () {
    return (dispatch,getState) => {
        dispatch(loading(true));
        dispatch(clearShipments());
        let store = getState().authState.store;

        return dispatch(fetchResponsibleShipments(store)).then(response => {
            if(response.error) {
                dispatch(notification.refreshAction());
                dispatch(loading(false));
                return;
            }

            // Verify response structure
            if (!response.data || !response.data.shipments) {
                console.error('Unexpected response structure:', response);
                dispatch(loading(false));
                return;
            }

            const shipments = response.data.shipments.map(shipment => {
                return {
                    shipmentId: shipment.shipment_id,
                    incrementId: shipment.shipment_id,
                    carrier: shipment.carrier,
                    method: shipment.shipping_method,
                    brand: shipment.brand,
                    status: shipment.status,
                    pickslipUrl: shipment.pickslip_url,
                    fulfilledAt: shipment.fulfilled_at,
                    orderedAt: shipment.ordered_at,
                    customerName: shipment.customer_name,
                    items: shipment.shipment_items.map(item => ({
                        shipmentItemId: item.shipment_item_id,
                        name: item.name,
                        size: item.size,
                        sku: item.sku,
                        imageUrl: item.image_url,
                        // Backwards compatibility fields
                        localId: item.sku,
                        availability: true,
                        reason: null
                    })),
                    // Backwards compatibility fields
                    onlineStoreId: shipment.brand,
                    stage: null,
                    labelsPrinted: false,
                    createdAt: shipment.ordered_at // For timer functionality
                };
            });

            // Handle the new response structure
            const sortedShipments = shipments.sort((a, b) => {
                if (isPriorityShipment(a,b)) {
                    return comparePriorityShipment(a, b);
                }
                return compareShipment(a,b);
            });

            if (sortedShipments.some((shipment) => shipment.labelsPrinted)) {
                dispatch(setManifest('disabled'));
            }

            dispatch(addShipment(sortedShipments));
            dispatch(updateShipmentsWithFulfiller(getState().shippingState.fulfiller.futuraId));
            dispatch(loading(false));
        }).catch(() => {
            dispatch(notification.refreshAction());
            dispatch(loading(false));
        })
    }
}

export function getProductImages(skus) {
    return (dispatch, getState) => {
        let store_view_code = getState().authState.store_view_code;
        return getProductsBySkus(skus, store_view_code).then(response => {
            dispatch(updateProductImages(response.images));
        })
    }
}

export function getCCShipments() {
    return (dispatch,getState) => {
        dispatch(loading(true));
        dispatch(clearShipments());
        let store = getState().authState.store;
        return dispatch(fetchResponsibleShipments(store)).then(response => {
            if (response.error) {
                console.log('error retrieving responsible shipments');
                dispatch(loading(false));
            } else {
                console.log('Received shipments response:', response);

                // Check if response.data and response.data.shipments exist
                if (!response.data || !response.data.shipments) {
                    console.error('Unexpected response structure:', response);
                    dispatch(loading(false));
                    return;
                }

                // Filter for CLICKCOLLECT shipments that are FULFILLED
                let shipments = response.data.shipments.filter(obj => 
                    obj.carrier === "CLICKCOLLECT" && 
                    obj.status === "FULFILLED"
                );

                // Map to internal format
                const mappedShipments = shipments.map(shipment => ({
                    shipmentId: shipment.shipment_id,
                    incrementId: shipment.shipment_id,
                    carrier: shipment.carrier,
                    method: shipment.shipping_method,
                    brand: shipment.brand,
                    status: shipment.status,
                    pickslipUrl: shipment.pickslip_url,
                    fulfilledAt: shipment.fulfilled_at,
                    orderedAt: shipment.ordered_at,
                    customerName: shipment.customer_name,
                    bagCount: 1,
                    items: shipment.shipment_items.map(item => ({
                        name: item.name,
                        size: item.size,
                        sku: item.sku,
                        imageUrl: item.image_url,
                        localId: item.sku,
                        availability: true,
                        reason: null
                    }))
                }));

                dispatch(addShipment(mappedShipments));
                dispatch(updateShipmentsWithFulfiller(getState().shippingState.fulfiller.futuraId));
                dispatch(loading(false));
            }
        })
    }
}

export function searchShipments(text, archived = false) {
    return (dispatch,getState) => {
        dispatch(loading(true));
        let allShipments = getState().shippingState.allShipments;
        let store = getState().authState.store;
        if(archived) {
            if(text.length > 0) {
                return dispatch(getFulfilledByIncrement(text,store)).then(response => {
                    if(response.status) {
                        console.log(response);
                        if(response.data && response.data.shipments && response.data.shipments.length > 0) {
                            // Map shipments to internal format here too
                            const mappedShipments = response.data.shipments.map(shipment => ({
                                shipmentId: shipment.shipment_id,
                                incrementId: shipment.shipment_id,
                                carrier: shipment.carrier,
                                method: shipment.shipping_method,
                                brand: shipment.brand,
                                status: shipment.status,
                                pickslipUrl: shipment.pickslip_url,
                                fulfilledAt: shipment.fulfilled_at,
                                orderedAt: shipment.ordered_at,
                                customerName: shipment.customer_name,
                                items: shipment.shipment_items.map(item => ({
                                    name: item.name,
                                    size: item.size,
                                    sku: item.sku,
                                    imageUrl: item.image_url,
                                    localId: item.sku,
                                    availability: true,
                                    reason: null
                                })),
                                onlineStoreId: shipment.brand,
                                stage: null,
                                labelsPrinted: false,
                                createdAt: shipment.ordered_at
                            }));
                            dispatch(addShipment(mappedShipments));
                            dispatch(setSearchedShipments(mappedShipments));
                            dispatch(loading(false));
                        } else {
                            dispatch(getShipments());
                            dispatch(setSearchedShipments([]));
                        }
                    } else {
                        console.log('error');
                        dispatch(setSearchedShipments([]));
                    }
                });
            } else {
                dispatch(getShipments());
                dispatch(setSearchedShipments([]));
            }
        } else {
            if(text.length > 0) {
                // Update to use shipmentId instead of incrementId if that's the new identifier
                let newArray = allShipments.filter(x => x.shipmentId.includes(text));
                dispatch(addShipment(newArray));
                dispatch(setSearchedShipments(newArray));
                dispatch(loading(false));
            } else {
                dispatch(getShipments());
                dispatch(setSearchedShipments([]));
            }
        }
    };
}

export function reloadShipments() {
    return (dispatch,getState) => {
        dispatch(clearShipments());
        dispatch(loading(true));
        let store = getState().authState.store;
        return dispatch(fetchResponsibleShipments(store)).then(response => {
            if(response.error) {
                console.log('error retrieving responsible shipments');
                dispatch(loading(false));
            } else {
                // Check if response.data and response.data.shipments exist
                if (!response.data || !response.data.shipments) {
                    console.error('Unexpected response structure:', response);
                    dispatch(loading(false));
                    return;
                }
                
                // Map response to match component expectations
                const shipments = response.data.shipments.map(shipment => ({
                    shipmentId: shipment.shipment_id,
                    incrementId: shipment.shipment_id,
                    carrier: shipment.carrier,
                    method: shipment.shipping_method,
                    brand: shipment.brand,
                    status: shipment.status,
                    pickslipUrl: shipment.pickslip_url,
                    fulfilledAt: shipment.fulfilled_at,
                    orderedAt: shipment.ordered_at,
                    customerName: shipment.customer_name,
                    items: shipment.shipment_items.map(item => ({
                        name: item.name,
                        size: item.size,
                        sku: item.sku,
                        imageUrl: item.image_url,
                        localId: item.sku,
                        availability: true,
                        reason: null
                    })),
                    onlineStoreId: shipment.brand,
                    stage: null,
                    labelsPrinted: false,
                    createdAt: shipment.ordered_at
                }));
                dispatch(addShipment(shipments));
                dispatch(updateShipmentsWithFulfiller(getState().shippingState.fulfiller.futuraId));
                dispatch(loading(false));
            }
        })
    }
}

/**
 * Function that notifies frontend user when there is any updates with a shipment triggered by the API
 * @param shipmentId
 * @param type
 * @returns {function(*, *): (*|undefined)}
 */
export function updateShipmentLive(shipmentId,type){
    return (dispatch,getState) => {
        //let loggedIn = getState().shippingState.loggedIn
        return dispatch(fetchSingleReponsibleShipment(shipmentId)).then(response => {
            if(response.status){
                let shipment = response.shipment
                dispatch(updateShipmentInView(shipment));
                //dispatch(notification.shipmentNotification(createShipmentMessage(type,shipment)));
            }
        })
        if(loggedIn){
        }else {
            //dispatch(notification.shipmentNotification('There was an update with a shipment'));
        }
    }
}

/**
 * Function that notifies frontend user when there is a new shipment for their store triggered by the API
 * @param shipmentId
 * @returns {function(*, *): *}
 */
export function addShipmentLive(shipmentId){
    return (dispatch,getState) => {
        let loggedIn = getState().shippingState.loggedIn
        if(loggedIn){
            return dispatch(fetchSingleReponsibleShipment(shipmentId)).then(response => {
                let shipment = response.shipment
                dispatch(addNewShipmentToView(shipment));
                //dispatch(notification.shipmentNotification(createShipmentMessage("ADD_SHIPMENT",shipment)));
            })
        }else {
            //dispatch(notification.shipmentNotification('You have a new shipment to fulfill'));
        }
    }
}

export const getFocusedShipment = (shipment) => (dispatch, getState) => {
    const currentFocused = getState().shippingState.focusedShipment;
    if (currentFocused !== shipment) {
        dispatch(setFocusedShipment(shipment));
    }
};


/**
 * Updating shipments in the backend for C&C / SFS
 * @param shipmentId - shipment to update
 * @param status - what status we are updating the backend with
 * @param reason - if was rejected, a reason is provided
 * @param collection - if collection si true, shipment will be treated as a click and collect order and follows a different flow
 * @returns {Function}
 */
export function updateShipment(shipmentId, status, reason=false, collection=false) {
    return (dispatch,getState) => {
        console.log('Starting updateShipment:', { shipmentId, status, reason, collection });
        dispatch(loading(true));
        dispatch(updateShipmentStatus(shipmentId, status));

        let shipments = getState().shippingState.shipments;
        let storeId = getState().authState.store;
        console.log('Store ID:', storeId);

        let shipmentToEdit = shipments.find(x => x.shipmentId === shipmentId);
        console.log('Found shipment to edit:', shipmentToEdit);

        if (!shipmentToEdit) {
            console.error('Shipment not found:', shipmentId);
            dispatch(notification.errorNotification('Shipment not found! Please try again'));
            dispatch(loading(false));
            return;
        }

        // Clear label and add fulfiller info
        shipmentToEdit.label = "";
        const fulfiller = getState().shippingState.fulfiller.futuraId;
        console.log('Adding fulfiller to shipment:', fulfiller);
        shipmentToEdit.fulfiller = fulfiller;

        if (collection) {
            console.log('Processing collection shipment');
            if (status === "COMPLETE") {
                console.log('Marking collection as COMPLETE');
                dispatch(fulfilShipment(shipmentToEdit, storeId, status))
                    .then((response) => {
                        if (response.status) {
                            dispatch(loading(false));
                            dispatch(notification.successNotification('Shipment successfully picked up.'));
                        } else {
                            throw new Error('Invalid response status');
                        }
                    })
                    .catch((e) => {
                        console.error('Error fulfilling collection shipment:', e);
                        dispatch(notification.errorNotification('Network error! Please try again'));
                        dispatch(loading(false));
                        dispatch(updateShipmentStage('STAGE_THREE', shipmentId));
                    });
            } else if (status === "REJECTED") {
                console.log('Processing REJECTED collection');
                const rejectedItems = shipmentToEdit.items
                    .filter(item => item.availability === false && item.reason)
                    .map(item => ({
                        shipment_item_id: item.shipmentItemId,
                        rejection_reason: mapRejectionReason(item.reason)
                    }));
                console.log('Rejected items:', rejectedItems);

                dispatch(rejectShipment(shipmentId, rejectedItems))
                    .then((response) => {
                        console.log('Reject shipment response:', response);
                        if (response.status) {
                            if ('rejection' in response) {
                                console.log('Creating faulty item PDF');
                                let pdf = createFaultyItemPdf(response.rejection);
                                let url = printPreview(pdf);
                                dispatch(setIbt(shipmentId, url));
                            }
                            dispatch(loading(false));
                        } else {
                            throw new Error('Rejection failed');
                        }
                    })
                    .catch((e) => {
                        console.error('Error rejecting collection shipment:', e);
                        dispatch(notification.errorNotification('Network error! Please try again'));
                        dispatch(loading(false));
                        dispatch(updateShipmentStage('STAGE_THREE', shipmentId));
                    });
            } else if (status === "COLLECTED") {
                dispatch(collectShipment(shipmentId))
                    .then((response) => {
                        if (response.message === 'success') {
                            dispatch(loading(false));
                            dispatch(notification.successNotification('Shipment successfully marked as collected.'));
                        } else {
                            throw new Error('Collect shipment failed');
                        }
                    })
                    .catch((e) => {
                        console.error('Error collecting shipment:', e);
                        dispatch(notification.errorNotification('Network error! Please try again'));
                        dispatch(loading(false));
                    });
            } else {
                console.log('Processing regular collection fulfillment');
                dispatch(loading(false));
            }
        } else {
            console.log('Processing non-collection shipment');
            if (status === "COMPLETE") {
                console.log('Marking non-collection as COMPLETE');
                dispatch(updateShipmentStage('STAGE_THREE', shipmentId));
                // dispatch(removeShipmentFromView({shipmentId}));
                dispatch(loading(false));
            } else if (status === "REJECTED") {
                console.log('Processing REJECTED non-collection');
                const rejectedItems = shipmentToEdit.items
                    .filter(item => item.availability === false && item.reason)
                    .map(item => ({
                        shipment_item_id: item.shipmentItemId,
                        rejection_reason: mapRejectionReason(item.reason)
                    }));
                console.log('Rejected items for non-collection:', rejectedItems);

                dispatch(rejectShipment(shipmentId, rejectedItems))
                    .then((response) => {
                        console.log('Non-collection reject response:', response);
                        if (response.status) {
                            if ('rejection' in response) {
                                console.log('Creating faulty item PDF for rejected non-collection');
                                let pdf = createFaultyItemPdf(response.rejection);
                                let url = printPreview(pdf);
                                dispatch(setIbt(shipmentId, url));
                            }
                        }
                        dispatch(loading(false));
                    })
                    .catch((e) => {
                        console.error('Error rejecting non-collection shipment:', e);
                        dispatch(notification.errorNotification('Network error! Please try again'));
                        dispatch(loading(false));
                        dispatch(updateShipmentStage('STAGE_THREE', shipmentId));
                    });
            }
        }
    };
}

// Add helper function to map frontend rejection reasons to API format
function mapRejectionReason(reason) {
    const reasonMap = {
        'out_of_stock': 'OUT_OF_STOCK',
        'on_hold': 'ON_HOLD',
        'manufacturing_fault': 'MANUFACTURING_FAULT',
        'damaged_item_sell_in_store': 'DAMAGED_ITEM',
        'faulty': 'FAULTY'
    };
    return reasonMap[reason] || reason.toUpperCase();
}

/**
 * Calcuilating Australia post labels
 * @param shipmentId
 * @param parcels - parcel information for auspost label creation in magento {parcelType, parcelItems}
 * @param createdAt
 * @returns {Function}
 */
export function calculateLabels(shipmentId, parcels, createdAt) {
    return (dispatch, getState) => {
        dispatch(loading(true));

        // Transform parcels into package_composition format
        const packageComposition = parcels.map(parcel => ({
            shipment_items: parcel.items.map(item => ({
                shipment_item_id: item.shipmentItemId,
                weight: item.weight || "0.2" // Default weight if not provided
            })),
            packaging_weight: parcel.parcelType.weight || "0.1", // Default weight if not provided
            final_weight: calculateFinalWeight(parcel) // Helper function to sum weights
        }));

        // Get shipping address from shipment
        const shipment = getState().shippingState.shipments.find(s => s.shipmentId === shipmentId);
        if (!shipment) {
            dispatch(notification.errorNotification('Shipment not found'));
            dispatch(loading(false));
            return;
        }

        console.log('Shipment:', shipment); // Debug log

        // Safely get shipping address data from customerData
        const shippingAddress = {
            first_name: shipment.customerData?.fName || "",
            last_name: shipment.customerData?.lName || "",
            company: shipment.customerData?.company || "",
            street_one: shipment.customerData?.address || "",
            street_two: shipment.customerData?.address2 || "",
            city: shipment.customerData?.city || "",
            postcode: shipment.customerData?.postcode || "",
            state: shipment.customerData?.state || shipment.customerData?.region || "QLD", // try both state and region
            country: shipment.customerData?.country || "",
            phone_number: shipment.customerData?.phone || ""
        };

        console.log('Shipping Address:', shippingAddress); // Debug log

        const fulfiller = getState().shippingState.fulfiller.name;
        const fulfilledAt = moment().format('YYYY-MM-DD HH:mm:ss');

        dispatch(fetchLabel(shipmentId, packageComposition, shippingAddress, fulfiller, fulfilledAt))
            .then(response => {
                if (response.error) {
                    dispatch(notification.errorNotification(errorTypes[response.error] ? errorTypes[response.error] : "Error calculating Label! Please contact dev support"));
                    dispatch(loading(false));
                    dispatch(updateShipmentStage('STAGE_TWO', shipmentId));
                } else if (response.code === 200 && response.data?.label_url) {
                    // Open label URL in new window
                    const labelUrl = response.data.label_url;
                    const theWindow = window.open(labelUrl);
                    if (theWindow) {
                        const theDoc = theWindow.document;
                        const theScript = document.createElement('script');
                        function injectThis() {
                            window.location.reload();
                        }
                        theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
                        theDoc.body.appendChild(theScript);
                    } else {
                        dispatch(notification.errorNotification('Pop-up blocked. Please allow pop-ups to view label.'));
                    }
                    dispatch(setLabels(shipmentId, response.data.label_url));
                    dispatch(updateShipmentStage('STAGE_THREE', shipmentId));
                    dispatch(loading(false));
                } else {
                    dispatch(notification.errorNotification('Invalid response format from label service'));
                    dispatch(loading(false));
                    dispatch(updateShipmentStage('STAGE_TWO', shipmentId));
                }
            })
            .catch((e) => {
                console.log(e.message);
                dispatch(notification.errorNotification('Network error! Please try again'));
                dispatch(loading(false));
                dispatch(updateShipmentStage('STAGE_TWO', shipmentId));
            });
    };
}

// Helper function to calculate final weight
function calculateFinalWeight(parcel) {
    const itemsWeight = parcel.items.reduce((sum, item) => sum + (parseFloat(item.weight) || 0.2), 0);
    const packagingWeight = parseFloat(parcel.parcelType.weight) || 0.1;
    return (itemsWeight + packagingWeight).toFixed(1);
}

export function getFulfillerData  (futuraId) {
    return(dispatch,getState) => {
        dispatch(loading(true));
        let storeId = getState().authState.store;

        dispatch(fetchFulfillerData(storeId, futuraId)).then(response => {
            if (!response.status) {
                dispatch(notification.errorNotification(response.error + '! Please try again'));
                dispatch(loading(false));
            } else {
                console.log('fulfiller', response.fulfillerInfo);
                dispatch(updateFulfiller(response.fulfillerInfo));
                dispatch(store.fetchSingleStore(storeId)).then(response => {
                    if (response.pickupInfo) {
                        dispatch(setAuspostInfo(response.pickupInfo));
                        dispatch(loading(false));
                        dispatch(loggedIn(true));
                    }
                }).catch((e) => {
                    checkSession();
                    console.log(e);
                    dispatch(loading(false));
                });
            }
        }).catch((e) => {
            checkSession();
            console.log(e);
            dispatch(loading(false));
        })
    }
}

export function getStorePendingCount(){
    return (dispatch,getState) => {
        let store = getState().authState.store;
        dispatch(fetchPendingDetails(store)).then(response=>{
            //console.log(response);
            dispatch(addPendingOverview(response.data));
        })
    }
}

/**
 * Get Manifest will check if there is a manifest for today
 * if not it will generate and spend 5 seconds awaiting a response from magento.
 * @returns {Function}
 */
export function getManifest() {
    return(dispatch,getState) => {
        let storeId = parseInt(getState().authState.store);
        dispatch(fetchManifest(storeId))
            .catch(error => {
                // Only handle non-404 errors here
                if (!error.response || error.response.status !== 404) {
                    console.error('Manifest fetch error:', error);
                    dispatch(notification.errorNotification('Network error while fetching manifest. Please try again.'));
                }
                return error.response; // Pass through the response for 404 handling
            })
            .then(response => {
                // Handle case where manifest is not found
                if (response?.code === 404) {
                    dispatch(notification.warningNotification('There are no shipments to manifest at this time.'));
                    return;
                }

                // Check if we have a valid response with manifests
                if (response?.code === 200 && response?.data?.manifests?.length > 0) {
                    const manifest = response.data.manifests[0];
                    dispatch(notification.successNotification('Manifest ready! Go ahead and download it'));
                    dispatch(setManifest(manifest.manifestUrl));
                } else {
                    // More descriptive error message
                    const errorMsg = response.message || 'Failed to generate manifest. Please try again.';
                    dispatch(notification.errorNotification(errorMsg));
                }
            });
    }
}

export function getPickslips(shipmentId) {
    return (dispatch,getState) => {
        dispatch(loading(true));
        let store = getState().authState.store;

        console.log('store', store);
        return dispatch(fetchPickslip(shipmentId, store)).then(response => {
            if(response.code === 200 && response.data?.pickslip_url) {
                let randomQuery = Math.random();
                const pickslipUrl = `${config.s3.PICKSLIPS}${response.data.pickslip_url}?${randomQuery}`;
                const theWindow = window.open(pickslipUrl);
                if (theWindow) {
                    const theDoc = theWindow.document;
                    const theScript = document.createElement('script');
                    function injectThis() {
                        window.location.reload();
                    }
                    theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
                    theDoc.body.appendChild(theScript);
                } else {
                    dispatch(notification.errorNotification('Pop-up blocked. Please allow pop-ups to view pickslip.'));
                }
                dispatch(loading(false));
            } else {
                dispatch(notification.errorNotification('Failed to load pickslip'));
                dispatch(loading(false));
            }
        }).catch(error => {
            console.error('Error loading pickslip:', error);
            dispatch(notification.errorNotification('Error loading pickslip'));
            dispatch(loading(false));
        });
    }
}

export function getAllPickslips(store, type, web_store){
    if (type !== 'all') {
        return (dispatch,getState) => {
            dispatch(loading(true));
            dispatch(notification.warningNotification('Pickslips will open in a new tab when loaded'));
            let store = parseInt(getState().authState.store);
            dispatch(fetchAllPickslips(store, type, web_store)).then(response => {
                const pickslip = response.data.pickslip_url;
                let randomQuery = Math.random();
                const theWindow = window.open(config.s3.PICKSLIPS + pickslip +'?' + randomQuery);
                const theDoc = theWindow.document;
                const theScript = document.createElement('script');
                function injectThis() {
                    window.location.reload();
                }
                theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
                theDoc.body.appendChild(theScript);
                dispatch(loading(false));
            });
        }
    } else {
        return (dispatch,getState) => {
            let store = getState().authState.store;
            let randomQuery = Math.random();
            const pickslip_name = `${store.toLowerCase()}_${type.toLowerCase()}_${web_store ? `${web_store.toLowerCase()}_` : ''}}pickslips.pdf?`;
            const theWindow = window.open(config.s3.PICKSLIPS + pickslip_name + randomQuery);
            const theDoc = theWindow.document;
            const theScript = document.createElement('script');
            function injectThis() {
                window.location.reload();
            }
            theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
            theDoc.body.appendChild(theScript);
        }
    }
}

const isToday = (someDate) => {
    return moment.tz("Australia/Brisbane").isSame(someDate, 'day');
};

const printPreview = (data, type = 'application/pdf') => {
    let blob = b64toBlob(data, type);
    const blobURL = URL.createObjectURL(blob);
    return blobURL;
};

const b64toBlob = (content, contentType) => {
    contentType = contentType || '';
    const sliceSize = 512;
    // method which converts base64 to binary
    const byteCharacters = window.atob(content);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
        type: contentType
    }); // statement which creates the blob
    return blob;
};

/**
 * Generating a click and collect label
 * @param shipment - The shipment object containing shipmentId and brand
 * @param parcels - The parcels to include in the label
 * @returns {Function}
 */
export function generateCCLabel(shipment,parcels){
    return (dispatch,getState) => {
        let shipmentId = shipment.shipmentId;
        let store = getState().authState.store;
        let brand = shipment.brand;
        dispatch(loading(true));
        dispatch(updateShipmentStage('STAGE_THREE',shipmentId));
        dispatch(loading(false));
        dispatch(fetchCCLabel(store, shipmentId, brand)).then(response=>{
            if(response.message === 'success' && response.data.pickslip_url){
                try{
                    const randomQuery = Math.random();
                    const pickslipUrl = `${config.s3.PICKSLIPS}${response.data.pickslip_url}?${randomQuery}`;
                    const theWindow = window.open(pickslipUrl);

                    if (theWindow) {
                        const theDoc = theWindow.document;
                        const theScript = document.createElement('script');
                        function injectThis() {
                            window.location.reload();
                        }
                        theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
                        theDoc.body.appendChild(theScript);
                    } else {
                        dispatch(notification.errorNotification('Pop-up blocked. Please allow pop-ups to view pickslip.'));
                    }

                    dispatch(setLabels(shipmentId, pickslipUrl));
                    dispatch(updateShipmentStage('STAGE_THREE',shipmentId));
                    dispatch(loading(false));
                }catch (e) {
                    dispatch(notification.errorNotification('Error creating label! please contact dev support'));
                    dispatch(loading(false));
                    dispatch(updateShipmentStage('STAGE_TWO',shipmentId));
                    console.log(e);
                }
            }else {
                dispatch(notification.errorNotification('Network error! Please try again or contact dev support'));
                dispatch(loading(false));
                dispatch(updateShipmentStage('STAGE_TWO',shipmentId));
            }
        }).catch((e)=>{
            dispatch(notification.errorNotification('Error creating label! please contact dev support'));
            dispatch(loading(false));
            dispatch(updateShipmentStage('STAGE_TWO',shipmentId));
            console.log(e);
        })
    }
}

function checkSession(){
    return (dispatch) => {
        cogLib.checkActiveSession().then(response => {
            dispatch(notification.errorNotification('please refresh the page'));
        });
    }
}

/**
 * When an item has a manufacturing fault this function creates a pdf for the store to stick to the item.
 * this pdf contains Date,Ibt,staff till number, sku,
 * @param data
 * @return {string}
 */
function createFaultyItemPdf(data) {
    let {ibt, fulfiller, skus, qty} = data
    let doc = new jsPDF();
    let ibt_number = ibt ? ibt : "not assigned"
    let staff_number = fulfiller ? fulfiller : "unknown"
    let r_codes = skus ? skus.join() : "unknown"
    doc.setFontSize(26);
    doc.text("Date: " + new Date().toLocaleDateString(), 15, 25);
    doc.text("IBT NUMBER: " + ibt_number, 15, 45);
    doc.text("R_CODES: " + r_codes, 15, 65);
    doc.text("Total Units: " + qty, 15, 85);
    doc.text("STAFF NUMBER: " + staff_number, 15, 110);
    let dataURL = doc.output();
    return btoa(dataURL);
}

/**
 * Takes in any number of shipments and returns true if at least one shipment is a high priority shipment
 * (Same Day or CC that is not 'fulfilled')
 *
 * @param shipments
 * @returns {boolean}
 */
function isPriorityShipment(...shipments) {
    for (let shipment of shipments) {
        if (shipment.method === METHOD.SAME_DAY) {return true;}
        if (shipment.method === METHOD.CLICKCOLLECT && shipment.status !== STATUS.FULFILLED) {return true;}
    }
    return false;
}

/**
 * Compares two shipments where at least one is a high priority shipment.
 * If both shipments are high priority sort by createdAt date otherwise sort based on the shipment with higher
 * priority.
 *
 * @param shipmentA
 * @param shipmentB
 * @returns {number|number}
 */
function comparePriorityShipment(shipmentA, shipmentB) {

    //Both Shipments are High Priority determine order based on createdAt
    if ((shipmentA.method === METHOD.SAME_DAY || shipmentA.method === METHOD.CLICKCOLLECT) &&
        (shipmentB.method === METHOD.SAME_DAY || shipmentB.method === METHOD.CLICKCOLLECT)) {
        return new Date(shipmentA.createdAt) - new Date(shipmentB.createdAt);
    }

    //At least one of the shipments is high priority. Therefore if shipmentA doesn't contain a high priority method
    //shipmentB is the higher priority shipment.
    return (shipmentA.method !== METHOD.SAME_DAY && shipmentA.method !== METHOD.CLICKCOLLECT) ? 1 : -1;
}

/**
 * Compare two shipments where both shipments are NOT high priority. If shipping methods are the same sort based on
 * createdAt date. Otherwise sort by Express -> Standard -> CC (pending pickup)
 *
 * @param shipmentA
 * @param shipmentB
 * @returns {number}
 */
function compareShipment(shipmentA, shipmentB) {
    //Shipments with Labels printed but incomplete should be up the top
    if (shipmentA.labelsPrinted || shipmentB.labelsPrinted) {
        return (shipmentA.labelsPrinted) ? -1 : 1;
    }

    //Both Shipments have the same method sort by createdAt
    if (shipmentA.method === shipmentB.method) {
        return new Date(shipmentA.createdAt) - new Date(shipmentB.createdAt);
    }

    //Express takes priority over standard
    if (shipmentA.method === METHOD.EXPRESS && shipmentB.method === METHOD.STANDARD) {
        return -1;
    }

    if (shipmentA.method === METHOD.STANDARD && shipmentB.method === METHOD.EXPRESS) {
        return 1;
    }

    //Only Options left are CC (fulfilled) and Standard/Express. fulfilled shipments are the lowest priority
    return (shipmentA.status === STATUS.FULFILLED) ? 1 : -1;
}

/**
 * Collects a shipment using the shipment ID
 * @param {string} shipmentId - ID of the shipment to collect
 * @returns {Function} - Redux thunk function
 */
export function collectShipment(shipmentId) {
    return (dispatch) => {
        console.log('Collecting shipment:', shipmentId);
        return lambda.collectShipment(shipmentId)
            .then(response => {
                console.log('Collect shipment response:', response);
                return response;
            })
            .catch(error => {
                console.error('Error in collectShipment:', error);
                throw error;
            });
    };
}