import React, {Component} from "react";
import {Row, Col} from "react-bootstrap";
import config from "../../../../config";

const ShipmentItemNew = props => {
    const {media,sku,product_name,qty,itemId,size,localId,imageUrl} = {...props.item.item};
    // Determine which CDN to use based on shipment brand
    const cdnUrl = props.brand ? config.shopifyCdn[props.brand.toLowerCase()] : config.shopifyCdn.us;

    // Create a fallback image URL in case imageUrl is undefined
    const imageSrc = imageUrl ? cdnUrl + '/' + imageUrl.split('/').pop() : '';

    return (
        <div key={localId} className={"shipment-label-table-item item-"+localId}>
            <Row>
                <Col sm={12} style={{padding:"1%"}}>
                    <img className={"label-image"} src={imageSrc} alt={<i className="fad fa-tshirt"/>}/>
                </Col>
            </Row>
            <Row>
                <Col sm={12} className={"shipment-label-item-qty"}>
                    <span>SIZE:</span> <span>{size}</span>
                </Col>
            </Row>
        </div>
    )
};

export default ShipmentItemNew
