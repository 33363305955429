import {API} from "aws-amplify/lib/index";
import METHOD from "../constants/shipping/MethodTypesNew";

/*
DYNAMO CALLS
 */
export const getResponsibleShipments = (store) => {
  return API.get("shipping-v2", `/shipments`, {
    queryStringParameters: {
      store_id: store
    }
  });
};

export const getSingleResponsibleShipment = (shipmentId) =>{
  return API.get("shipping-v2", `/shipments/${shipmentId}`);
};

/*
MAGENTO CALLS
 */

export const getLabel = (shipmentId, packageComposition, shippingAddress, fulfiller, fulfilledAt) => {
  const requestData = {
    shipment_id: shipmentId,
    package_composition: packageComposition,
    fulfiller: fulfiller ?? 'SYSTEM',
    fulfilled_at: fulfilledAt
  }

  console.log('Making label request with:', {
    data: requestData
  });

  return API.post("shipping-v2", '/fulfil', {
    body: requestData
  })
  .then(response => {
    console.log('Label API response:', response);
    return response;
  })
  .catch(error => {
    console.error('Label API error:', error);
    throw error;
  });
};


export const getManifest = (storeId) => {
  return API.post("shipping-v2", '/manifest', {
    body: {
      store_id: String(storeId)
    }
  });
};

export const getPickslip = (shipmentId, storeId) => {
    return API.get("shipping-v2", '/pickslips', {
        queryStringParameters: {
            store_id: storeId,
            shipment_id: shipmentId
        }
    });
};

export const getAllPickslips = (store, type = 'ALL', brand) => {
  return  API.get("shipping-v2", '/pickslips',{
    queryStringParameters: {
      store_id: store,
      shipping_method: type,
      brand: brand
    }
  })
};

export const fulfillShipment = (storeId, shipmentToEdit, status, collection)=> {
  return API.post('shipment','magento/fulfill/old', {
    body: {
      storeId, shipment:shipmentToEdit, status, collection
    }
  });
};


export const fulfilShipment = (shipment, storeId, status) => {
  return API.post('shipping-v2','/fulfil', {
    body: {
      shipment,
      storeId,
      status
    }
  });
};


export const rejectShipment = (shipmentId, rejectedItems) => {
  console.log('Rejecting shipment:', shipmentId, rejectedItems);
  return API.post('shipping-v2', '/reject', {
    body: {
      shipment_id: shipmentId,
      rejected_items: rejectedItems
    }
  });
};


export const generateCCLabel = (storeId, shipmentId, brand)=> {
  return API.get('shipping-v2', '/pickslips', {
    queryStringParameters: {
      store_id: storeId,
      shipment_id: shipmentId,
      shipping_method: METHOD.CLICKCOLLECT,
      brand
    }
  });
};

export const collectShipment = (shipmentId) => {
  return API.post('shipping-v2', '/collect', {
    body: {
      shipment_id: shipmentId
    }
  });
};

export const queryFulfilledByIncrement = (incrementId,storeId) => {
  return API.post('shipment','fulfilled/get/increment',{
    body:{
      storeId: storeId,
      incrementId: incrementId
    }
  })
};

export const getPendingShipments = (store = null,date = null,userId) =>{
  return API.post('shipping','shipping/shipment/report/get', {
    body: {
      store,
      date,
      userId,
      query:'query-pending'
    }
  });
};

export const getFulfillerData = (storeId ,futuraId) =>{
  return API.post('shipment','fulfiller/lookup', {
    body: {
      storeId,
      futuraId
    }
  });
};