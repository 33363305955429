import React, { Component } from "react";
import {connect} from "react-redux";
import {Row, Col, Dropdown} from "react-bootstrap";
import ShippingListNew from "./ShippingListNew";
import ShipmentSearchFilter from "./ShippingSearchNew"
import './shipping.css'
import RealTimeAgent from "../../components/mqtt/MqttAgent";
import {getFulfillerData,getManifest, getAllPickslips, loggedIn,getStorePendingCount} from "../../actions/ShippingActionsNew";
import config from "../../config";
import {Auth} from "aws-amplify";
import {withRouter} from "react-router-dom";
import TillLogin from "../../components/login/staff-number-login";
import PickSlipDropdownMenuNew from "./components/PickSlipDropdownMenuNew";

const mapStateToProps = state => {
    return {user: state.authState, loading:state.shippingState.isLoading, fulfiller:state.shippingState.fulfiller,
        manifest:state.shippingState.manifest, shipments:state.shippingState.shipments,
        auspost:state.shippingState.auspost,loggedIn:state.shippingState.loggedIn,
        pendingOverview:state.shippingState.pendingOverview};
};

const mapDispatchToProps = dispatch =>{
    return {
        getFulfillerData: (futuraId) => {dispatch(getFulfillerData(futuraId))},
        getManifest: ()=>{dispatch(getManifest())},
        getPickslips: (store, type, web_store)=>{dispatch(getAllPickslips(store, type, web_store))},
        getStorePendingCount : ()=> {dispatch(getStorePendingCount())},
        logIn: (logIn)=>{dispatch(loggedIn(logIn))}
    }
};

class ShippingNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            disabledManifest:false
        };
    }

    componentDidMount() {
        this.inactivityTime();
        this.props.getStorePendingCount();
    }

    inactivityTime = function () {
        let parent = this;
        var time;
        window.onload = resetTimer;
        // DOM Events
        document.onmousemove = resetTimer;
        document.onkeypress = resetTimer;

        function logout() {
            if(parent.props.loggedIn){
                parent.setState({loggedIn:false});
                parent.props.logIn(false);
            }else {
                Auth.signOut();
                localStorage.clear();
                parent.props.history.push({});
                window.location.reload();
            }

        }
        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(logout, 600000);
        }
    };

    handleLogin= (till_number) =>{
        event.preventDefault();
        this.props.getFulfillerData(till_number);
    };

    getWebStorePickSlips = (method, web_store) => {
        this.props.getPickslips(this.props.user.store, method, web_store);
    }

    render(){
            // && this.props.fulfiller.futuraId removed logging in for dev
            if(this.props.loggedIn){
                return (
                    <div className={"shipping-wrapper"}>
                        <div className={"heading"}>
                            <div className={"left-heading"}>
                                <h1 className={"heading-text"}>Your Shipments</h1>
                                {this.props.loading === true ?
                                    <h2 className={"small-heading-text"}>
                                        Hey {this.props.fulfiller.fName}! hang tight while we load your store orders.
                                    </h2>
                                :
                                    <div>
                                        {
                                            this.props.auspost ?
                                                <h2 className={"small-heading-text"}>
                                                    Call Australia Post to book today's parcel pick-up before {this.props.auspost.time} at {this.props.auspost.phone}
                                                </h2>
                                                :null
                                        }
                                        <h2 className={"small-heading-text"}>
                                            Hey {this.props.fulfiller.fName}! You have {this.props.shipments.length } orders to fulfill today.
                                        </h2>
                                    </div>

                                }

                            </div>
                        </div>
                        <Row>
                            <Col sm={5}>
                                <ShipmentSearchFilter />
                            </Col>
                            <Col sm={7}>
                                <div style={{textAlign:"right"}}>
                                    <PickSlipDropdownMenuNew generatePickSlips={this.getWebStorePickSlips} />
                                    <button className={"all-shipment-button"} onClick={this.props.getManifest} disabled={this.props.manifest === 'disabled'}>
                                        Generate Manifest
                                    </button>
                                    {this.props.manifest!== 'disabled' && this.props.manifest ?
                                        <form target="_blank" method="get" action={this.props.manifest}>
                                            <button className={"all-shipment-button"} type="submit">
                                                Download Manifest
                                            </button>
                                        </form>
                                        : null
                                    }
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <ShippingListNew fulfiller ={this.state.fulfiller}/>
                        </div>
                        <div className="new-badge" style={{
                            position: 'fixed',
                            bottom: '15px',
                            right: '20px',
                            backgroundColor: '#00a63e',
                            color: 'white',
                            padding: '5px 10px',
                            borderRadius: '4px',
                            fontWeight: 'bold',
                            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                            zIndex: 1000
                        }}>NEW</div>
                    </div>
                        )

            }else {
                return (
                    <div className={"wrapper shipping-wrapper-login"}>
                        <div className={"store-login-wrapper"}>
                            <Row style={{marginBottom:'10px'}}>
                                <Col sm={12}>
                                    <span>Login in to view Today's shipments</span>
                                </Col>
                            </Row>
                            <Row>
                                <TillLogin loading = {this.props.loading} handleLogin={this.handleLogin}/>
                            </Row>
                        </div>
                        <RealTimeAgent />
                        <div className="new-badge" style={{
                            position: 'fixed',
                            bottom: '15px',
                            right: '20px',
                            backgroundColor: '#00a63e',
                            color: 'white',
                            padding: '5px 10px',
                            borderRadius: '4px',
                            fontWeight: 'bold',
                            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                            zIndex: 1000
                        }}>NEW</div>
                    </div>
                )
            }
    }
}


const ShippingTestNew = connect(mapStateToProps, mapDispatchToProps)(ShippingNew);
export default withRouter(ShippingTestNew);